import React from "react";
import { Spinner } from "react-bootstrap";
import { useBuyToken } from "../../../context/BuyTokenContext";

const ConnectButton = () => {
  const { status, connect } = useBuyToken();

  const isDisabled = (status: string) => {
    return status === "unavailable" || status !== "notConnected";
  };

  const showSpinner = (status: string) => {
    return status === "connecting" ? (
      <>
        <Spinner animation={"border"} className="spinner" />
        {"Connecting"}
      </>
    ) : status === "unavailable" ? (
      "Metamask unavailable"
    ) : (
      "Connect Wallet"
    );
  };

  return (
    <button
      disabled={isDisabled(status)}
      onClick={connect}
      className="connect-wallet-button"
    >
      {showSpinner(status)}
    </button>
  );
};

export default ConnectButton;
