import React from "react";
import { buildMetamaskLink } from "../../../utils/utils";
import Video from "../../components/Video";

const BuyBoxWithoutMetamask = () => {
  const handleClicked = () => {
    window.location.replace(buildMetamaskLink("/buy"));
  };

  return (
    <div className="buy-box-mobile">
      <p>To buy G2N tokens you have to open this page in Metamask browser</p>
      <button onClick={handleClicked}>
        <img
          src={"images/png/metamask.png"}
          alt="metamask"
          className="metamask"
        />
        Open Metamask
      </button>
      <hr />
      <p>Watch on YouTube how to buy on mobile device</p>
      <Video mobile={true} link={"https://www.youtube.com/embed/qkKn12lYg2w"} />
    </div>
  );
};

export default BuyBoxWithoutMetamask;
