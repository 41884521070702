import React from "react";
import Video from "./Video";

const VideoModal = () => {
  return (
    <Video
      link={"https://www.youtube.com/embed/YWLpPU7NmII?controls=1&autoplay=1"}
      mobile={true}
    />
  );
};

export default VideoModal;
