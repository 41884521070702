interface TokenDetailProps {
  label: string
  children?: JSX.Element
}

const TokenDetail: React.FC<TokenDetailProps> = ({ label, children }) => {
  return <div className="token-detail">
    <span className="token-detail-label">{label}</span>
    {children && <div className="token-detail-content">{children}</div>}
  </div>
}

export default TokenDetail