import React from "react";
import "./css/InvestorsModal.css";

const InvestorsModal = () => {
  return (
    <div className="info-for-investors-modal">
      <div className="video-part">
        <iframe
          height={"100%"}
          width={"100%"}
          src="https://www.youtube.com/embed/dUdzLhiXKPY"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
      <div className="info-part">
        {/* <img className="x" src="" alt="escape button" /> */}
        <img
          src="/images/svg/brand-mobile.svg"
          alt="go2nft logo"
          className="logo"
        />
        <h4>Presale has officially been canceled.</h4>
        <p className="s1">
          Everyone that has invested will have the funds returned by the 5th of
          November 2022.
        </p>
        <p className="s2">
          There will also be a special reward for all the people who believed in
          the project and supported it from the start. The wallet addresses of
          investors will be put onto a special whitelist that will enable
          everyone to invest in the project with some of the bigger funds that
          are coming onboard on a later stage once the company has reached
          sufficient growth and once the market sentiment has improved.
        </p>
      </div>
    </div>
  );
};

export default InvestorsModal;
