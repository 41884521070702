import React from "react";

import { socials } from "../../../networkInfo";

import FooterSocialMedium from "../../components/FooterSocialMedium";

const BuyTokenFooter = () => {
  return (
    <section id="buy-token-footer">
      <div className="footer-social-media">
        {Object.entries(socials).map(([name, url]) => {
          return (
            <FooterSocialMedium
              name={name}
              iconName={name}
              url={url}
              key={url}
            />
          );
        })}
      </div>
      <img
        id="footer-logo"
        src="images/svg/footer-logo.svg"
        alt="logo"
        height="36px"
      />
    </section>
  );
};

export default BuyTokenFooter;
