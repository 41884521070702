export const SM_BREAKPOINT = 576;
export const MD_BREAKPOINT = 768;
export const LG_BREAKPOINT = 991;
export const XL_BREAKPOINT = 1120;
export const XL_REAL_BREAKPOINT = 1199;
export const XL_1200 = 1200;
export const XL_1160 = 1160;
export const CUSTOM_XL_BREAKPOINT = 1250;
export const XXL_BREAKPOINT = 1300;

export const observerOptions = {
  root: null,
  threshold: 0.5,
  rootMargin: "-150px"
};

export const NFT_LANGUAGE_OBJECT = "nft_%Rbe876R&6";
export const FIRST_TIME_MOBILE = "#%Bhb4^&#";
export const LITEPAPER_PATH = "/Go2NFT_LightPaper_1.1_18.05.pdf";
