import React from "react";
import { binance } from "../networkInfo";
import { BurnValidator } from "../utils/BurnValidator";
import useProvider from "./useProvider";
import { useReflinkForContract } from "./useReflinkForContract";
import { useMetaMask } from "metamask-react";
import { BigNumber } from "ethers";

const useValidateOracleBeforeTransaction = (
  skeyValueBigInt: bigint,
  skeyUnitPrice: bigint
) => {
  const [oracleErrors, setOracleErrors] = React.useState<string[]>([]);
  const [oracleLoading, setOracleLoading] = React.useState(false);
  const { bscProvider } = useProvider();
  const { burnContractInfo } = useReflinkForContract();
  const { account } = useMetaMask();

  const options = {
    provider: bscProvider,
    burnPresaleAddress: burnContractInfo.contract,
    senderAddress: account!,
    oracleAddress: binance.burnOracle,
    skeyValue: BigNumber.from(skeyValueBigInt),
    skeyUnitPrice: BigNumber.from(skeyUnitPrice),
    oracleUrl: binance.oracleUrl,
    onError: console.error
  };

  const validator = new BurnValidator(options);

  React.useEffect(() => {
    (async () => {
      setOracleLoading(true);
      setOracleErrors(await validator.validate());
      setOracleLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { oracleErrors, oracleLoading };
};

export default useValidateOracleBeforeTransaction;
