import React from "react";
import { useTranslation } from "react-i18next";
import { NFT_LANGUAGE_OBJECT } from "../constants";

export const TranslationContext = React.createContext(null as any);

export const TranslationProvider: React.FC = (props) => {
  const { i18n } = useTranslation();
  const DEFAULT_USER_LANGUAGE = window.navigator.language;
  const PREFFERED_LANGUAGE = localStorage.getItem(NFT_LANGUAGE_OBJECT ?? null);

  const getUserLanguage = () => {
    if (PREFFERED_LANGUAGE) {
      return PREFFERED_LANGUAGE;
    } else {
      return DEFAULT_USER_LANGUAGE === "ko" ? "ko" : "en";
    }
  };

  const [currentLanguage, setCurrentLanguage] = React.useState<string>(
    getUserLanguage()
  );

  const setLanguage = (lang: string) => {
    localStorage.setItem(NFT_LANGUAGE_OBJECT, lang);
    setCurrentLanguage(lang);
  };

  React.useEffect(() => {
    i18n.changeLanguage(currentLanguage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLanguage]);

  return (
    <TranslationContext.Provider value={[setLanguage, currentLanguage]}>
      {props.children}
    </TranslationContext.Provider>
  );
};

export const useLanguage = () => React.useContext(TranslationContext);
