import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container } from "react-bootstrap";

interface XXLBoxType {
  id?: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
}

const XXLBox = ({ id, children, style }: XXLBoxType) => {
  return (
    <Container id={id} fluid={"xxl"} style={{ ...style }}>
      {children}
    </Container>
  );
};

export default XXLBox;
