import React from "react";

interface ActionColumnProps {
  iconSrc: string;
  title?: string;
  description?: string;
  buttonLabel?: string;
  onClick?: () => any;
  buttonDisabled?: boolean;
  color: string;
}

const ActionColumn: React.FC<ActionColumnProps> = ({
  iconSrc,
  title,
  description,
  buttonLabel,
  onClick,
  buttonDisabled,
  color,
}) => {
  return (
    <div className="action-column">
      <img className="action-column-icon" src={iconSrc} alt="icon" />
      <p className="action-column-title">{title}</p>
      <p className="action-column-content" style={{ color: color }}>
        {description}
      </p>
      <button
        className="buy-column-button"
        onClick={onClick}
        disabled={buttonDisabled}
      >
        {buttonLabel}
      </button>
    </div>
  );
};

export default ActionColumn;
