import React from "react";
import { BuyTokenProvider } from "../../context/BuyTokenContext";
import { GasPriceProvider } from "../../context/GasPriceContext";
import BuyTokenContent from "../sections/BuyTokenContent/BuyTokenContent";
import BuyTokenNavbar from "../sections/BuyTokenNavbar/BuyTokenNavbar";
import "../css/buy-token.css";
import BuyTokenFooter from "../sections/BuyTokenFooter/BuyTokenFooter";
import TokenDetails from "../sections/TokenDetails/TokenDetails";
import MetamaskActions from "../sections/MetamaskActions/MetamaskActions";
import { ModalInfoProvider } from "../../context/ModalInfoContext";
import { ConfigProvider } from "../../context/ConfigContext";

const BuyTokenPage = () => {
  return (
    <section id="buy-token">
      <ConfigProvider>
        <ModalInfoProvider>
          <GasPriceProvider>
            <BuyTokenProvider>
              <BuyTokenNavbar />
              <BuyTokenContent />
              <MetamaskActions />
              <TokenDetails />
              <BuyTokenFooter />
            </BuyTokenProvider>
          </GasPriceProvider>
        </ModalInfoProvider>
      </ConfigProvider>
    </section>
  );
};

export default BuyTokenPage;
