import React from "react";

interface ErrorModalProps {
  info: string;
  resetStatus: Function;
}

const ErrorModal = ({ info, resetStatus }: ErrorModalProps) => {
  return (
    <div className="modal-wrapper">
      <div className="done-modal-top">
        <h5 className="done-title">Error</h5>
        <img
          className="error-icon"
          src={"images/svg/error-icon.svg"}
          alt="error"
        />
        <p className="done-info error">Error message: {info}</p>
        {/* <span className="error-message">Error message: {info}</span> */}
        <img
          role="button"
          src={"images/svg/close-cross.svg"}
          alt="close"
          className="cross"
          onClick={() => resetStatus()}
        />
        <button className="close-orange" onClick={() => resetStatus()}>
          Close
        </button>
      </div>
    </div>
  );
};

export default ErrorModal;
