import React from "react";

interface DoneModalProps {
  info: string;
  resetStatus: Function;
  url: string;
}

const DoneModal = ({ info, resetStatus, url }: DoneModalProps) => {
  return (
    <div className="modal-wrapper">
      <div className="done-modal-top">
        <h5 className="done-title">Well done</h5>
        <img className="succes" src={"images/svg/succes.svg"} alt="Succes" />
        <p className="done-info">Succesfull Transaction</p>
        <img
          role="button"
          src={"images/svg/close-cross.svg"}
          alt="close"
          className="cross"
          onClick={() => resetStatus()}
        />
      </div>
      <div className="done-modal-bottom">
        <h5 className="done-hash-title">Tx Hash</h5>
        <p className="view">link to transaction</p>
        <a className="done-hash" href={url} target="_blank" rel="noreferrer">
          {info}
        </a>
        <button onClick={() => resetStatus()} className="close">
          Close
        </button>
      </div>
    </div>
  );
};

export default DoneModal;
