import React from "react";
import { Spinner } from "react-bootstrap";
import {
  TransactionStatus,
  useModalInfo
} from "../../../context/ModalInfoContext";
import useValidateOracleBeforeTransaction from "../../../hook/useValidateOracleBeforeTransaction";
import Backdrop from "../../components/Backdrop";
import ConfirmButton from "../../components/ConfirmButton";

interface ConfirmModalProps {
  skeyValue: string;
  skeyValueBigInt: bigint;
  skeyUnitPrice: bigint;
  g2nValue: string;
  g2nCost: any;
  gasPrice: bigint | undefined;
  resetStatus: Function;
  confirm: Function;
  networkSettings: any;
}

const ConfirmModalSkey = ({
  skeyValue,
  g2nValue,
  g2nCost,
  gasPrice,
  resetStatus,
  confirm,
  networkSettings,
  skeyValueBigInt,
  skeyUnitPrice
}: ConfirmModalProps) => {
  const { oracleErrors, oracleLoading } = useValidateOracleBeforeTransaction(
    skeyValueBigInt,
    skeyUnitPrice
  );
  const { setInfoMessage, setTransactionStatus } = useModalInfo();

  const confirmDisabled = () => {
    return oracleErrors.length !== 0 || oracleLoading;
  };

  React.useEffect(() => {
    if (oracleErrors.length !== 0) {
      setInfoMessage(oracleErrors[0]);
      setTransactionStatus(TransactionStatus.ORACLE_DEAD);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oracleErrors]);

  return (
    <Backdrop>
      <div className="modal-wrapper">
        <div className="confirm-modal-top">
          <h5 className="confirm-title">Confirm</h5>
          <div className="confirm-input">
            <p className="confirm-token-value">{skeyValue}</p>
            <div className="confirm-token-logo">
              <p className="confirm-token-name">Skey</p>
              <img
                src={"images/svg/Skey-token.svg"}
                alt="Skey logo"
                width={32}
              />
            </div>
          </div>
          <img
            src={"images/svg/double-arrow.svg"}
            alt="Double-direction"
            className="double-arrow"
            width={18}
          />
          <div className="confirm-input">
            <p className="confirm-token-value">{g2nValue}</p>
            <div className="confirm-token-logo">
              <p className="confirm-token-name">G2N</p>
              <img src={"images/svg/G2N-token.svg"} alt="G2N logo" />
            </div>
          </div>
          <p className="confirm-skey-price">Price: 1 Skey = {g2nCost} G2N</p>
          <img
            role="button"
            src={"images/svg/close-cross.svg"}
            alt="close"
            className="cross"
            onClick={() => resetStatus()}
          />
        </div>
        <div className="confirm-modal-bottom">
          <h5 className="confirm-title details">Transaction Details</h5>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              height: "max-content",
              marginBottom: "50px"
            }}
          >
            <span className="confirm-fee">Estimated fee</span>
            <div className="fee-wrap">
              {gasPrice ? (
                <p className="confirm-fee-value">{gasPrice}</p>
              ) : (
                <Spinner animation={"border"} size={"sm"} />
              )}
              <p className="confirm-fee-value">Skey</p>
            </div>
          </div>
          <ConfirmButton
            disabled={confirmDisabled()}
            loading={oracleLoading}
            onClick={confirm}
          />
        </div>
      </div>
    </Backdrop>
  );
};

export default ConfirmModalSkey;
