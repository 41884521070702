import React from "react";
import Routing from "./routing/Routing";
import Backdrop from "./UI/components/Backdrop";
import InvestorsModal from "./UI/components/InvestorsModal";
import "./UI/sections/css/sections.css";

function App() {
  return (
    <>
      <Routing />
      <Backdrop>
        <InvestorsModal />
      </Backdrop>
    </>
  );
}

export default App;
