import React from "react";
import { shortAddress } from "../../../utils/utils";

interface AddressProps {
  account: string;
}

const Address = ({ account }: AddressProps) => {
  return <div className="short-address">{shortAddress(account, 3)}</div>;
};

export default Address;
