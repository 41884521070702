import React from "react";
import { WayOfBuyingG2n, useConfig } from "../context/ConfigContext";

const useSkeyUnitPrice = () => {
  const [skeyTokenPrice, setSkeyTokenPrice] = React.useState<bigint>(0n);
  const [skeyPriceLoading, setSkeyPriceLoading] = React.useState(false);
  const [skeyPriceError, setSkeyPriceError] = React.useState("");

  const { paymentMethod } = useConfig();

  const getCurrentSkeyUnitPrice = async (coinName: string) => {
    const baseUrl = "https://api.coingecko.com/api/v3";

    const params = new URLSearchParams({
      page: "1",
      depth: "false"
    });

    const path = `${baseUrl}/coins/${coinName}/tickers?${params}`;

    setSkeyPriceLoading(true);

    const resolved = await fetch(path)
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("Cannot get skey token price, step 1");
        }
        return res;
      })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        const value = data?.tickers?.[0]?.converted_last?.usd ?? null;
        if (!value) {
          throw new Error("Cannot get skey token price, step 2");
        }
        if (typeof value !== "number" || !Number.isFinite(value)) {
          throw new Error("Cannot get skey token price, step 3");
        }
        return value;
      })
      .then((skeyUnitPrice) => {
        if (!skeyUnitPrice) {
          throw new Error("Cannot get skey token price, step 4");
        }
        return BigInt(Math.round(skeyUnitPrice * 10 ** 8));
      })
      .catch((err) => {
        setSkeyPriceError(err);
        console.log(err);
        return 0n;
      });
    setSkeyPriceLoading(false);

    return resolved;
  };

  React.useEffect(() => {
    if (paymentMethod === WayOfBuyingG2n.BURN_SKEY) {
      (async () => {
        setSkeyTokenPrice(await getCurrentSkeyUnitPrice("skey-network"));
      })();
    }
  }, [paymentMethod]);

  return { skeyTokenPrice, skeyPriceLoading, skeyPriceError };
};

export default useSkeyUnitPrice;
