import { BigToken, Token } from "./BigToken";
import { BigTokenConverter } from "./BigTokenConverter";

const listOfExcludedTokens: Token[] = ["G2N"];

export class InputValidator {
  input: string;
  fromTokenName: Token;
  fromToken: BigToken;
  fromTokenBalance: BigToken;
  fromTokenUnitPrice: number | bigint;
  toToken: BigToken;
  toTokenBalance: BigToken;
  minDollar: BigToken;
  maxDollar: BigToken;
  // error: string;

  constructor(
    input: string,
    fromTokenName: Token,
    fromTokenBalance: BigToken,
    fromTokenUnitPrice: number | bigint,
    toToken: BigToken,
    toTokenBalance: BigToken
  ) {
    this.input = input;
    this.fromTokenUnitPrice = fromTokenUnitPrice;
    this.fromTokenName = fromTokenName;
    this.fromToken = new BigToken(
      this.fromTokenName,
      this.input,
      this.fromTokenUnitPrice
    );
    this.fromTokenBalance = fromTokenBalance;
    this.toToken = toToken;
    this.toTokenBalance = toTokenBalance;
    this.minDollar = new BigToken("$", 1, 1);
    this.maxDollar = new BigToken("$", 15000, 1);
    // this.error = "";
  }
  validate() {
    const converter = new BigTokenConverter();
    //Must be number
    if (isNaN(Number(this.input))) {
      return "Value is not a number";
    }
    //Cannot be empty
    if (this.input === "") {
      return "Input cannot be empty";
    }
    //Value must be positive
    if (Number(this.input) <= 0) {
      return "Value must be positive";
    }
    //Must be finite
    if (!isFinite(Number(this.input))) {
      return "Wrong value";
    }
    // Min 1$
    if (
      this.fromToken.totalTokensPriceInBigInt() <
      this.minDollar.totalTokensPriceInBigInt()
    ) {
      return "Min value in $ is 1$";
    }
    //Max 15000$
    if (
      this.fromToken.totalTokensPriceInBigInt() >
      this.maxDollar.totalTokensPriceInBigInt()
    ) {
      return "Max value in $ is 15000$";
    }
    //User hasn't got enough balance
    if (listOfExcludedTokens.includes(this.fromTokenName)) {
      if (
        this.toTokenBalance.toBigInt() <
        converter.convertTokensBasedOnPriceBigInt(
          this.fromToken,
          this.toToken,
          this.toToken.token
        )
      ) {
        return "Too low balance";
      }
    } else {
      if (this.fromTokenBalance.toBigInt() < this.fromToken.toBigInt()) {
        return "Too low balance";
      }
    }
  }
}
