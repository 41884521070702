import React from "react";
import "../../UI/sections/css/sections.css";

interface VideoProps {
  link: string;
  mobile?: boolean;
  className?: string;
}

const Video = ({ link, mobile, className }: VideoProps) => {
  return (
    <iframe
      width="100%"
      height="300px"
      src={link}
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      className={`${mobile ? "mobile-video" : "video"} ${className}`}
    ></iframe>
  );
};

export default Video;
