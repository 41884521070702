import React from "react";

interface ValueInDollarsProps {
  priceInDollars: string;
}

const BnbInDollars = ({ priceInDollars }: ValueInDollarsProps) => {
  const priceBigInt = Number(parseFloat(priceInDollars));

  const getPrice = () => {
    if (priceBigInt > 15000) return "0.00";

    return priceInDollars;
  };
  return <p>Total Price: ~${getPrice()}</p>;
};

export default BnbInDollars;
