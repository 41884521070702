import React from "react";
import { Spinner } from "react-bootstrap";
import Backdrop from "./Backdrop";

interface ConfirmModalBNBProps {
  bnbValue: string;
  g2nValue: string;
  g2nCost: any;
  gasPrice: bigint | undefined;
  resetStatus: Function;
  confirm: Function;
  networkSettings: any;
}

const ConfirmModalBNB = ({
  resetStatus,
  confirm,
  bnbValue,
  g2nValue,
  g2nCost,
  gasPrice,
  networkSettings
}: ConfirmModalBNBProps) => {
  return (
    <Backdrop>
      <div className="modal-wrapper">
        <div className="confirm-modal-top">
          <h5 className="confirm-title">Confirm</h5>
          <div className="confirm-input">
            <p className="confirm-token-value">{bnbValue}</p>
            <div className="confirm-token-logo">
              <p className="confirm-token-name">BNB</p>
              <img src={"images/png/bnb.png"} alt="BNB logo" width={32} />
            </div>
          </div>
          <img
            src={"images/svg/double-arrow.svg"}
            alt="Double-direction"
            className="double-arrow"
            width={18}
          />
          <div className="confirm-input">
            <p className="confirm-token-value">{g2nValue}</p>
            <div className="confirm-token-logo">
              <p className="confirm-token-name">G2N</p>
              <img src={"images/svg/G2N-token.svg"} alt="BNB logo" />
            </div>
          </div>
          <p className="confirm-bnb-price">Price: 1 BNB = {g2nCost} G2N</p>
          <img
            role="button"
            src={"images/svg/close-cross.svg"}
            alt="close"
            className="cross"
            onClick={() => resetStatus()}
          />
        </div>
        <div className="confirm-modal-bottom">
          <h5 className="confirm-title details">Transaction Details</h5>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              height: "max-content",
              marginBottom: "50px"
            }}
          >
            <span className="confirm-fee">Estimated fee</span>
            <div className="fee-wrap">
              {gasPrice ? (
                <p className="confirm-fee-value">
                  {(
                    Number(gasPrice * networkSettings.gasAmount) /
                    10 ** 18
                  ).toFixed(8)}
                </p>
              ) : (
                <Spinner animation={"border"} size={"sm"} />
              )}
              <p className="confirm-fee-value">BNB</p>
            </div>
          </div>
          <button className="close-orange" onClick={() => confirm()}>
            Confirm swap
          </button>
        </div>
      </div>
    </Backdrop>
  );
};

export default ConfirmModalBNB;
