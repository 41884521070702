import React from "react";

interface BackdropProps {
  resetModal?: Function;
}

const Backdrop: React.FC<BackdropProps> = ({ children, resetModal }) => {
  return <div className="modal-backdrop">{children}</div>;
};

export default Backdrop;
