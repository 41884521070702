import React from "react";

interface OracleDeadProps {
  resetStatus: Function;
  message: string;
}

const OracleDeadModal = ({ resetStatus, message }: OracleDeadProps) => {
  return (
    <div className="modal-wrapper">
      <div className="done-modal-top">
        <h5 className="done-title">Service unavailable.</h5>
        <img
          className="error-icon"
          src={"images/svg/warning-icon.svg"}
          alt="error"
        />
        <p className="info-description">{message}</p>
        <img
          role="button"
          src={"images/svg/close-cross.svg"}
          alt="close"
          className="cross"
          onClick={() => resetStatus()}
        />
        <button className="close-orange" onClick={() => resetStatus()}>
          Close
        </button>
      </div>
    </div>
  );
};

export default OracleDeadModal;
