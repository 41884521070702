import React from "react";
import { binance, ethereum } from "../networkInfo";
import { OldToken__factory } from "../Factories/factories/OldToken__factory";
import { Token__factory } from "../Factories/factories/Token__factory";

interface UseToken {
  bscProvider: any;
  ethProvider: any;
}

const useToken = ({ bscProvider, ethProvider }: UseToken) => {
  const g2nToken = React.useMemo(() => {
    return Token__factory.connect(binance.g2nToken, bscProvider);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bscProvider]); //static address

  const skeyToken = React.useMemo(() => {
    return OldToken__factory.connect(ethereum.skeyToken, ethProvider);
  }, [ethProvider]);

  return { g2nToken, skeyToken };
};

export default useToken;
