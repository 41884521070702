export const switchNetwork = async (ethereum: any, correctNetworkInfo: any) => {
  try {
    await ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: correctNetworkInfo.chainId }],
    });
  } catch (switchError: any) {
    // This error code indicates that the chain has not been added to MetaMask.
    if (switchError.code === 4902) {
      try {
        await ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: correctNetworkInfo.chainId,
              chainName: correctNetworkInfo.name,
              rpcUrls: [correctNetworkInfo.url],
              nativeCurrency: {
                name: correctNetworkInfo.nativeToken,
                symbol: correctNetworkInfo.nativeToken,
                decimals: 18,
              },
            },
          ],
        });
      } catch (addError) {
        console.log(addError);
      }
    }
    // handle other "switch" errors
  }
};
