/* eslint-disable @typescript-eslint/no-namespace */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { BaseProvider } from "@ethersproject/providers";
import { BigNumber, utils } from "ethers";
import { BurnPresale } from "../Factories/BurnPresale";
import { BurnPresale__factory } from "../Factories/factories/BurnPresale__factory";

export interface BurnValidationOptions {
  // rpc url provider
  provider: BaseProvider;
  // burn presale contract address
  burnPresaleAddress: string; //burnPresale
  // Address of transaction sender
  senderAddress: string;
  // Address of burn oracle
  oracleAddress: string;
  // Amount of skey to burn
  skeyValue: BigNumber;
  // Current skey price in usd (8 decimals)
  skeyUnitPrice: BigNumber;
  // Url to get oracle update timestamp
  oracleUrl: string; //oracle proxy url
  // console callback
  onError: <T>(message: T) => void;
}

export namespace BVErrors {
  export const STATIC_CALL_ERR = "static call failed";
  export const MAX_LIMIT_ERR = "max limit exceeded";
  export const MIN_LIMIT_ERR = "min limit not reached";
  export const NO_AVAILABLE_TOKENS_ERR = "no availale tokens";
  export const UNEXPECTED_ERR = "unexpected error";
  export const INVALID_STATUS_ERR = "invalid presale contract status";
  export const ORACLE_LOW_BALANCE_ERR = "oracle balance too low";
  export const ORACLE_ADDRESS_ERR = "invalid oracle address";
  export const TIMESTAMP_REQ_ERR = "timestamp request failed";
  export const ORACLE_INACTIVE_ERR = "oracle inactive";
}

type CheckResult = string | undefined;
type CheckMethod = () => Promise<CheckResult>;

export class BurnValidator {
  static readonly MAX_USD_LIMIT = BigNumber.from("1500000000000"); // 15k USD
  static readonly MIN_USD_LIMIT = BigNumber.from("100000000"); // 1 USD
  static readonly MIN_ORACLE_BALANCE = utils.parseEther("0.01");
  static readonly MAX_ORACLE_DELAY = 120000;

  private burn: BurnPresale;
  private fetch: typeof globalThis.fetch;

  constructor(private opts: BurnValidationOptions) {
    this.burn = BurnPresale__factory.connect(
      opts.burnPresaleAddress,
      opts.provider
    );

    if (!!globalThis.fetch) {
      this.fetch = globalThis.fetch.bind(globalThis);
    } else {
      this.fetch = null as any;
    }
  }

  public async validate(): Promise<string[]> {
    try {
      const errors = await Promise.all(
        this.allCheckMethods.map((method) => method.bind(this)())
      );

      return errors.filter(((e) => e) as (x: unknown) => x is string);
    } catch (e) {
      this.opts.onError(e);
      return [BVErrors.UNEXPECTED_ERR];
    }
  }

  private get usdValue() {
    return this.opts.skeyValue.mul(this.opts.skeyUnitPrice).div(10 ** 8);
  }

  private get allCheckMethods(): CheckMethod[] {
    return [
      // this.checkMaxLimit,
      // this.checkMinLimit,
      this.checkOracleBalance,
      this.checkOracleStaticCall
      // this.checkOracleStatus
    ];
  }

  private async checkOracleStatus(): Promise<CheckResult> {
    try {
      const res = await this.fetch(this.opts.oracleUrl);
      const data = await res.json();
      const timestamp = data?.updateTimestamp;

      if (typeof timestamp !== "number") {
        throw new Error("invalid timestamp value");
      }

      const active = timestamp > Date.now() - BurnValidator.MAX_ORACLE_DELAY;

      if (!active) return BVErrors.ORACLE_INACTIVE_ERR;
    } catch (e) {
      this.opts.onError(e);
      return BVErrors.TIMESTAMP_REQ_ERR;
    }
  }

  private async checkMaxLimit(): Promise<CheckResult> {
    if (this.usdValue.gt(BurnValidator.MAX_USD_LIMIT)) {
      return BVErrors.MAX_LIMIT_ERR;
    }
  }

  private async checkMinLimit(): Promise<CheckResult> {
    if (this.usdValue.lt(BurnValidator.MIN_USD_LIMIT)) {
      return BVErrors.MIN_LIMIT_ERR;
    }
  }

  private estimateReturnTokens(rate: BigNumber) {
    return this.opts.skeyValue
      .mul(this.opts.skeyUnitPrice)
      .div(rate)
      .div(10 ** 4);
  }

  private async checkOracleBalance(): Promise<CheckResult> {
    const balance = await this.opts.provider.getBalance(
      this.opts.oracleAddress
    );

    if (balance.lt(BurnValidator.MIN_ORACLE_BALANCE)) {
      return BVErrors.ORACLE_LOW_BALANCE_ERR;
    }
  }

  private async checkOracleStaticCall(): Promise<CheckResult> {
    const rate = await this.burn.saleData().then((d) => d.rate);

    try {
      await this.burn.callStatic.buyWithBurnOracle(
        this.opts.senderAddress,
        this.estimateReturnTokens(rate),
        utils.randomBytes(32),
        { from: this.opts.oracleAddress }
      );
    } catch (e: any) {
      if (typeof e?.message === "string") {
        return this.parseCallError(e.message);
      }

      this.opts.onError(e);
      return BVErrors.UNEXPECTED_ERR;
    }
  }

  private parseCallError(message: string): string {
    if (message.includes("stage is not sale")) {
      return BVErrors.INVALID_STATUS_ERR;
    }

    if (message.includes("no available tokens")) {
      return BVErrors.NO_AVAILABLE_TOKENS_ERR;
    }

    if (message.includes("caller is not burn oracle")) {
      return BVErrors.ORACLE_ADDRESS_ERR;
    }

    this.opts.onError(message);
    return BVErrors.STATIC_CALL_ERR;
  }
}
