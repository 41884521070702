import { useMetaMask } from "metamask-react";
import React from "react";
import { Spinner } from "react-bootstrap";
import { useConfig } from "../../context/ConfigContext";

interface ShortBalanceProps {
  balance: string | undefined;
  isCorrectNetwork: boolean;
  token: "BNB" | "G2N" | "Skey";
  loading: boolean;
}

const ShortBalance = ({
  balance,
  isCorrectNetwork,
  token,
  loading
}: ShortBalanceProps) => {
  const { status } = useMetaMask();
  const { networkSettings } = useConfig();

  const balanceOrLocked = (token: string): "Balance:" | "Total locked:" => {
    return token === "G2N" ? "Total locked:" : "Balance:";
  };

  const showBalance = () => {
    if (balance && !loading && isCorrectNetwork && status === "connected") {
      return (
        <span className="bnb-balance spin">
          <span>{balanceOrLocked(token)}</span>
          <span style={{ marginLeft: "3px", marginRight: "5px" }}>
            {balance}
          </span>
          <span>{token}</span>
        </span>
      );
    } else if (!isCorrectNetwork) {
      return <span>Switch to {networkSettings.name}</span>;
    } else if (status !== "connected") {
      return <span className="bnb-balance spin">Connect to metamask</span>;
    } else {
      return (
        <span className="bnb-loading spin">
          <span>{balanceOrLocked(token)}</span>
          <Spinner animation={"border"} className="spinner-img" size={"sm"} />
          <span>{token}</span>
        </span>
      );
    }
  };

  return <div style={{ display: "flex" }}>{showBalance()}</div>;
};

export default ShortBalance;
