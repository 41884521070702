import React from "react";
import { useLocation } from "react-router";
import { useConfig } from "../context/ConfigContext";
import { binance, Contract } from "../networkInfo";

export const useReflinkForContract = () => {
  const location = useLocation();
  const { networkSettings, paymentMethod } = useConfig();

  const [nativeContractInfo, setNativeContractInfo] = React.useState<Contract>(
    binance.presaleContract.default
  );
  const [burnContractInfo, setBurnContractInfo] = React.useState<Contract>(
    binance.burnPresale.default
  );
  const [allNativeContracts, setAllNativeContracts] = React.useState<
    Contract[] | []
  >([]);
  const [allBurnContracts, setAllBurnContracts] = React.useState<
    Contract[] | []
  >([]);

  const foo = new URLSearchParams(location.search);
  const reflink = foo.get("reflink");

  const getPresaleAddress = (reflink: string, config: any) => {
    for (const property in config.presaleContract) {
      if (reflink === property) {
        return config.presaleContract[property];
      }
    }
    return config.presaleContract.default;
  };

  const getBurnAddress = (reflink: string, config: any) => {
    for (const property in config.burnPresale) {
      if (reflink === property) {
        return config.burnPresale[property];
      }
    }
    return config.burnPresale.default;
  };

  const getAllPresaleContracts = (config: any): Contract[] => {
    const contracts = [];
    for (const contract in config.presaleContract) {
      contracts.push(config.presaleContract[contract]);
    }
    return [...contracts];
  };

  const getAllBurnContracts = (config: any): Contract[] => {
    const contracts = [];
    for (const contract in config.burnPresale) {
      contracts.push(config.burnPresale[contract]);
    }
    return [...contracts];
  };

  React.useEffect(() => {
    setBurnContractInfo(getBurnAddress(reflink!, binance));
    setNativeContractInfo(getPresaleAddress(reflink!, binance));
    setAllNativeContracts(getAllPresaleContracts(networkSettings));
    setAllBurnContracts(getAllBurnContracts(networkSettings));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethod, networkSettings]);

  return {
    nativeContractInfo,
    burnContractInfo,
    allNativeContracts,
    allBurnContracts
  };
};
