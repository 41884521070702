import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { ActiveSectionProvider } from "./context/ActiveSectionContext";
import { TranslationProvider } from "./context/TranslationContext";
import { MetaMaskProvider } from "metamask-react";
import "./translation/Translation";
import "./UI/components/css/components.css";
import "./index.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Scss/global.scss";

const html = document.querySelector("html");
html!.dataset.theme = "theme-light";

ReactDOM.render(
  <React.StrictMode>
    <MetaMaskProvider>
      <TranslationProvider>
        <ActiveSectionProvider>
          <App />
        </ActiveSectionProvider>
      </TranslationProvider>
    </MetaMaskProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
