import React from "react";
import { Spinner } from "react-bootstrap";

interface ConfirmButtonProps {
  disabled: boolean;
  loading: boolean;
  onClick: Function;
}

const ConfirmButton = ({ disabled, loading, onClick }: ConfirmButtonProps) => {
  return (
    <button
      className="close-orange"
      disabled={disabled}
      onClick={() => onClick()}
    >
      {loading ? <Spinner animation={"border"} /> : "Confirm swap"}
    </button>
  );
};

export default ConfirmButton;
