import React from "react";

interface ProgressBarProps {
  locked: number | undefined;
  total: number | undefined;
  round: number;
}

const ProgressBar = ({ locked, total, round }: ProgressBarProps) => {
  const calculateBoughtTokens = () => {
    if (locked && total) {
      return (locked * 100) / total;
    } else {
      return 0;
    }
  };

  const tokensLeft = () => {
    if (
      total !== undefined &&
      total !== null &&
      locked !== undefined &&
      locked !== null
    ) {
      return (total - locked).toString();
    } else {
      return 0;
    }
  };

  return (
    <div className="token-progress-bar">
      <div className="top-info">
        <span className="sold">Tokens sold in Round {round}</span>
        <span className="left">Tokens left: {tokensLeft()}</span>
      </div>
      <div className="bar-outer">
        <div
          className="bar-inner"
          style={{ width: `${calculateBoughtTokens()}%` }}
        >
          <div className="percentage">
            {calculateBoughtTokens().toFixed(1)}%
          </div>
        </div>
      </div>
      <div className="bottom-info">
        <span>0%</span>
        <span>100%</span>
      </div>
    </div>
  );
};

export default ProgressBar;
