export const colorNormal = {
  black: "#1B1A17",
  orange: "#FFA800",
  white: "#FFFFFF",
  bgGray: "#F6F6F6",
};
export const colorDark = [];

export const fontSizeDesktop = {
  header: "48px",
  heading: "36px",
  normal: "24px",
  stable16: "16px",
  small: "12px",
};
export const fontSizeMobile = {
  header: "48px",
  heading: "24px",
  normal: "16px",
  stable16: "16px",
  small: "12px",
};

export const marginDesktop = {
  xxl: "300px",
  big: "150px",
  medium: "111px",
  small: "50px",
};
export const marginMobile = {
  xxl: "269px",
  big: "112px",
  medium: "45px",
  small: "30px",
};
