export const floatToWei = (input: number, targetDecimals: number): bigint => {
  return BigInt(Math.round(input * 10 ** targetDecimals));
};

export const weiToFloat = (
  input: bigint,
  decimals: number,
  precision: number = 4
): number => {
  return (
    Number(
      (input * BigInt(10n) ** BigInt(precision)) /
        BigInt(10n) ** BigInt(decimals)
    ) /
    10.0 ** precision
  );
};
