import { ethers } from "ethers";
import React from "react";
import { binance, ethereum } from "../networkInfo";
import { useConfig } from "../context/ConfigContext";

const useProvider = () => {
  const { networkSettings } = useConfig();
  const bscProvider = React.useMemo(() => {
    return new ethers.providers.JsonRpcProvider(binance.url, {
      chainId: Number(binance.chainId),
      name: binance.name
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [networkSettings]); // providery się zmieniają w zależności od sieci na której jesteśmy

  const ethProvider = React.useMemo(() => {
    return new ethers.providers.JsonRpcProvider(ethereum.url, {
      chainId: Number(ethereum.chainId),
      name: ethereum.name
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [networkSettings]);

  return { bscProvider, ethProvider };
};

export default useProvider;
