import React from "react";
import { useMetaMask } from "metamask-react";
import { switchNetwork } from "../utils/switchNetwork";
import { useConfig, WayOfBuyingG2n } from "./ConfigContext";
import useProvider from "../hook/useProvider";
import usePresale from "../hook/usePresale";
import useBnbBalance from "../hook/useBnbBalance";
import useG2nBalance from "../hook/useG2nBalance";
import useSkeyBalance from "../hook/useSkeyBalance";
import { BigNumber, ethers } from "ethers";
import { useReflinkForContract } from "../hook/useReflinkForContract";
import { IMetaMaskContext } from "metamask-react/lib/metamask-context";
import useSkeyUnitPrice from "../hook/useSkeyPrice";
import { BigToken } from "../class/BigToken";
import { binance } from "../networkInfo";

interface BuyTokenState {
  status: IMetaMaskContext["status"];
  connect: () => any;
  account: string | null;
  isCorrectNetwork: boolean;
  chainId?: string | null;
  isConnectionFailed: boolean;
  buyToken: (amount: bigint) => Promise<ethers.providers.TransactionResponse>;
  burnSkey: (
    skeyInputValue: string
  ) => Promise<ethers.providers.TransactionResponse>;
  bnbBalance?: bigint;
  loadingBnb: boolean;
  g2nBalance?: bigint;
  loadingG2n: boolean;
  skeyBalance?: bigint;
  loadingSkey: boolean;
  bscProvider: any;
  nativeTokenPrice: any;
  networkSettings: any;
  options: any;
  paymentMethod: WayOfBuyingG2n;
  skeyTokenPrice: bigint | null;
  skeyPriceLoading: boolean;
}

export const BuyTokenContext = React.createContext<BuyTokenState>(null as any);

export const BuyTokenProvider: React.FC = (props) => {
  const { status, connect, account, chainId, ethereum } = useMetaMask();
  const [isCorrectNetwork, setIsCorrectNetwork] = React.useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { networkSettings, options, paymentMethod } = useConfig();
  const { bscProvider } = useProvider();
  const {
    nativePresale,
    nativeTokenPrice,
    updateNativeTokenPrice,
    setNativeTokenPrice
  } = usePresale();
  //contracts change depending on given reflink

  const isConnectionFailed = React.useMemo(() => {
    return !isCorrectNetwork || status !== "connected";
  }, [isCorrectNetwork, status]);

  const { bnbBalance, loadingBnb } = useBnbBalance({
    bscProvider,
    updateNativeTokenPrice,
    setNativeTokenPrice,
    status,
    account,
    isConnectionFailed,
    isCorrectNetwork
  });
  const { g2nBalance, loadingG2n } = useG2nBalance({
    account,
    status,
    isCorrectNetwork,
    isConnectionFailed
  });
  const { skeyBalance, loadingSkey } = useSkeyBalance({
    account,
    status,
    isCorrectNetwork,
    isConnectionFailed
  });

  const { skeyTokenPrice, skeyPriceLoading } = useSkeyUnitPrice();
  const { nativeContractInfo, burnContractInfo } = useReflinkForContract();

  // React.useEffect(() => {
  //   setIsCorrectNetwork(networkSettings.chainId === chainId);
  //   if (!isCorrectNetwork) {
  //     switchNetwork(ethereum, networkSettings);
  //   }
  // }, [chainId, ethereum, isCorrectNetwork, networkSettings]);

  const buyToken = React.useCallback(
    async (value: bigint) => {
      const metamaskProvider = new ethers.providers.Web3Provider(ethereum);
      await metamaskProvider.send("eth_requestAccounts", []);

      const gasLimit = await nativePresale.estimateGas.buy({
        value
      });

      return await metamaskProvider.getSigner().sendTransaction({
        to: nativeContractInfo.contract,
        value,
        gasLimit
      });
    },
    [ethereum, nativePresale, nativeContractInfo]
  );

  const packBurnTxData = (value: BigNumber, presale: string) => {
    const sig = ethers.utils
      .keccak256(ethers.utils.toUtf8Bytes("transfer(address,uint256)"))
      .substring(2, 10);

    const params = ethers.utils.defaultAbiCoder
      .encode(
        ["address", "uint256", "address"],
        [ethers.constants.AddressZero, value, presale]
      )
      .substring(2);

    return `0x${sig}${params}`;
  };

  const burnSkey = React.useCallback(
    async (skeyInputValue: string) => {
      const metamaskProvider = new ethers.providers.Web3Provider(
        (window as any).ethereum
      );
      await metamaskProvider.send("eth_requestAccounts", []);
      const skey = new BigToken("Skey", skeyInputValue, skeyTokenPrice);

      return await metamaskProvider.getSigner().sendTransaction({
        to: binance.skeyToken,
        data: packBurnTxData(
          BigNumber.from(skey.toBigInt()),
          burnContractInfo.contract
        ),
        gasLimit: 80000
      });
    },
    [burnContractInfo.contract, skeyTokenPrice]
  );

  return (
    <BuyTokenContext.Provider
      value={{
        status,
        connect,
        account,
        isCorrectNetwork,
        chainId,
        isConnectionFailed,
        buyToken,
        burnSkey,
        bnbBalance,
        loadingBnb,
        g2nBalance,
        loadingG2n,
        skeyBalance,
        loadingSkey,
        bscProvider,
        nativeTokenPrice,
        networkSettings,
        options,
        paymentMethod,
        skeyTokenPrice,
        skeyPriceLoading
      }}
    >
      {props.children}
    </BuyTokenContext.Provider>
  );
};

export const useBuyToken = () => React.useContext(BuyTokenContext);
