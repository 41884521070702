import React from "react";

interface OracleLoadingProps {
  resetStatus: Function;
  message: string;
}

const OracleLoadingModal = ({ resetStatus, message }: OracleLoadingProps) => {
  return (
    <div className="modal-wrapper">
      <div className="done-modal-top">
        <h5 className="done-title pending">Please wait.</h5>
        <img
          className="pending-spinner"
          src={"images/svg/spinner.svg"}
          alt="Pending"
        />
        <p className="pending-info">{message}</p>
        <img
          role="button"
          src={"images/svg/close-cross.svg"}
          alt="close"
          className="cross"
          onClick={() => resetStatus()}
        />
      </div>
    </div>
  );
};

export default OracleLoadingModal;
