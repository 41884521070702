import React from "react";
import { useConfig, WayOfBuyingG2n } from "../../context/ConfigContext";
import {
  ModalToOpen,
  TransactionStatus,
  useModalInfo
} from "../../context/ModalInfoContext";
import { binance, ethereum } from "../../networkInfo";
import Backdrop from "./Backdrop";
import DoneModal from "./DoneModal";
import ErrorModal from "./ErrorModal";
import InitializeModal from "./InitializeModal";
import OracleDeadModal from "./OracleDeadModal";
import OracleLoadingModal from "./OracleLoadingModal";
import PendingModal from "./PendingModal";
import RejectModal from "./RejectModal";
import VideoModal from "./VideoModal";

interface ModalInfoProps {
  status: TransactionStatus | ModalToOpen;
  info: any;
  setTransactionStatus: Function;
}

const ModalInfo = ({ status, info, setTransactionStatus }: ModalInfoProps) => {
  const { paymentMethod } = useConfig();
  const { transactionStatus } = useModalInfo();

  const buildUrl = (
    paymentMethod: WayOfBuyingG2n,
    transactionStatus: TransactionStatus | ModalToOpen
  ) => {
    if (paymentMethod === WayOfBuyingG2n.BNB) {
      return `${binance.scannerUrl}/tx/${info}`;
    }

    if (paymentMethod !== WayOfBuyingG2n.BURN_SKEY) return;
    if (transactionStatus === TransactionStatus.PENDING) {
      return `${ethereum.scannerUrl}/tx/${info}`;
    }
    if (transactionStatus === TransactionStatus.DONE) {
      return `${binance.scannerUrl}/tx/${info}`;
    }
  };

  const url = buildUrl(paymentMethod, transactionStatus);

  const resetStatus = () => {
    setTransactionStatus(TransactionStatus.DEFAULT);
  };

  const getModal = () => {
    if (status === TransactionStatus.DEFAULT) {
      return <></>;
    }
    if (status === ModalToOpen.VIDEO_MODAL) {
      return <VideoModal />;
    }
    if (status === TransactionStatus.INITIALIZED) {
      return <InitializeModal resetStatus={resetStatus} />;
    }
    if (status === TransactionStatus.DONE) {
      return <DoneModal info={info} resetStatus={resetStatus} url={url!} />;
    }
    if (status === TransactionStatus.PENDING) {
      return <PendingModal info={info} resetStatus={resetStatus} url={url!} />;
    }
    if (status === TransactionStatus.ERROR) {
      return <ErrorModal info={info} resetStatus={resetStatus} />;
    }
    if (status === TransactionStatus.REJECTED) {
      return <RejectModal info={info} resetStatus={resetStatus} />;
    }
    if (status === TransactionStatus.ORACLE_DEAD) {
      return <OracleDeadModal resetStatus={resetStatus} message={info} />;
    }
    if (status === TransactionStatus.ORACLE_LOADING) {
      return <OracleLoadingModal resetStatus={resetStatus} message={info} />;
    }
    return <></>;
  };

  return <Backdrop resetModal={resetStatus}>{getModal()}</Backdrop>;
};

export default ModalInfo;
