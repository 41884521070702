import React from "react";

interface FullBox {
  children: React.ReactNode;
  marginTopBottom?: number;
  marginBottom?: number;
  paddingTopBottom?: string;
  bgColor: string;
  paddingLeftRight?: string;
  className?: string;
  style?: React.CSSProperties;
}

const FullWidthBox = ({
  children,
  marginTopBottom,
  marginBottom,
  paddingTopBottom,
  bgColor,
  className,
  paddingLeftRight,
  style,
}: FullBox) => {
  return (
    <div
      className={`full-width-box ${className}`}
      style={{
        backgroundColor: bgColor,
        marginTop: marginTopBottom,
        marginBottom: marginBottom ? marginBottom : marginTopBottom,
        paddingTop: paddingTopBottom,
        paddingBottom: paddingTopBottom,
        paddingLeft: paddingLeftRight ? paddingLeftRight : "15px",
        paddingRight: paddingLeftRight ? paddingLeftRight : "15px",
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export default FullWidthBox;
