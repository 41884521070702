import React from "react";
import usePresale from "./usePresale";
import { useMetaMask } from "metamask-react";

const useG2nPrice = () => {
  const [nativeG2nPrice, setNativeG2nPrice] = React.useState<bigint>(0n);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [burnG2nPrice, setBurnG2nPrice] = React.useState<bigint>(0n);
  const { account } = useMetaMask();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { nativePresale, burnPresale } = usePresale();

  React.useEffect(() => {
    // if (!account) return;
    if (!!nativePresale) {
      (async () => {
        setNativeG2nPrice((await nativePresale.saleData()).rate.toBigInt());
      })();
    }

    // TODO
    // Add when burn presale is added

    // if (!!burnPresale) {
    //   (async () => {
    //     setBurnG2nPrice((await burnPresale.saleData()).rate.toBigInt());
    //   })();
    // }
  }, [
    account,
    nativePresale
    // burnPresale
  ]);

  return { nativeG2nPrice, burnG2nPrice };
};

export default useG2nPrice;
