import { MD_BREAKPOINT } from "../constants";
import useWindowDimensions from "./useWindowDimensions";
import {
  colorNormal,
  fontSizeDesktop,
  fontSizeMobile,
  marginDesktop,
  marginMobile,
} from "../presets/UIPresets";

const useUIPresets = () => {
  const { width } = useWindowDimensions();
  const colors = colorNormal;
  const textSizes = width > MD_BREAKPOINT ? fontSizeDesktop : fontSizeMobile;
  const marginLvl = width > MD_BREAKPOINT ? marginDesktop : marginMobile;
  return { colors, textSizes, marginLvl };
};

export default useUIPresets;
