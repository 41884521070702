import React from "react";
import Input from "./Input";

interface BnbInputProps {
  balanceForBNB: string;
  bnbInput: {
    value: string;
    touched: boolean;
    error: string;
  };
  isCorrectNetwork: boolean;
  isInputDisabled: Function;
  writeToBnbInput: Function;
  loadingBnb: boolean;
  setBnbInput: Function;
}

const BnbInput = ({
  balanceForBNB,
  bnbInput,
  isCorrectNetwork,
  isInputDisabled,
  writeToBnbInput,
  loadingBnb,
  setBnbInput
}: BnbInputProps) => {
  return (
    <Input
      className={"bnb-input crypto-input"}
      inputName={"BNB"}
      balance={balanceForBNB}
      value={bnbInput.value}
      touched={bnbInput.touched}
      error={bnbInput.error}
      isCorrectNetwork={isCorrectNetwork}
      placeholder={"BNB to spend"}
      isDisabled={isInputDisabled()}
      setNewValue={writeToBnbInput}
      loading={loadingBnb}
      click={() => setBnbInput({ ...bnbInput, touched: true })}
    />
  );
};

export default BnbInput;
