import React from "react";
import ShortBalance from "../../components/BnbShortBalance";

interface InputProps {
  value: string;
  touched: boolean;
  balance: string;
  inputName: "BNB" | "G2N" | "Skey";
  error: string;
  placeholder: string;
  isDisabled: boolean;
  className: string;
  isCorrectNetwork: boolean;
  loading: boolean;
  setNewValue: Function;
  click: Function;
}

const Input = ({
  value,
  touched,
  balance,
  error,
  inputName,
  placeholder,
  isDisabled,
  className,
  isCorrectNetwork,
  setNewValue,
  click,
  loading
}: InputProps) => {
  const getAltAndImg = (inputName: string) => {
    if (inputName === "BNB") return "images/png/bnb.png";
    if (inputName === "G2N") return "images/svg/G2N-token.svg";
    if (inputName === "Skey") return "images/svg/Skey-token.svg";

    throw new Error(`There is no token like: ${inputName}`);
  };

  return (
    <>
      <div className={`input-holder ${inputName}`}>
        <input
          className={className}
          type="number"
          name={inputName}
          placeholder={placeholder}
          value={value}
          onChange={(e) => setNewValue(e.target.value)}
          onFocus={() => click()}
          disabled={isDisabled}
        />
        {error && <div className="error">{error}</div>}
        <div className="input-absolute-element">
          <div className="token-balance-wrapper">
            <h5 className="crypto-title">{inputName}</h5>
            <div className="balance">
              <ShortBalance
                balance={balance}
                isCorrectNetwork={isCorrectNetwork}
                token={inputName}
                loading={loading}
              />
            </div>
          </div>
          <div>
            <img src={getAltAndImg(inputName)} alt={"logo"} height={"32px"} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Input;
