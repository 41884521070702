import React from "react";
import { Col, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import XXLBox from "../../components/XXLBox";
import {
  TransactionStatus,
  useModalInfo
} from "../../../context/ModalInfoContext";
import ProgressBar from "../../components/ProgressBar";
import { PresaleAggregator__factory } from "../../../Factories/factories/PresaleAggregator__factory";
import { useBuyToken } from "../../../context/BuyTokenContext";
import BuyBoxWithoutMetamask from "./BuyBoxWithoutMetamask";
import BuyBoxWithMetamask from "./BuyBoxWithMetamask";
import { LITEPAPER_PATH } from "../../../constants";
import { useConfig } from "../../../context/ConfigContext";
import useProvider from "../../../hook/useProvider";
import { binance } from "../../../networkInfo";

export const SALE_TOTAL_DEFAULT = 60000000;

const BuyTokenContent = () => {
  const { Modal } = useModalInfo();
  const { account, chainId, g2nBalance, status } = useBuyToken();
  const { transactionStatus } = useModalInfo();
  const { options, paymentMethod, setActive } = useConfig();
  const { bscProvider } = useProvider();
  const [total, setTotal] = React.useState<number>();
  const [locked, setLocked] = React.useState<number>();

  const aggregator = React.useMemo(() => {
    return PresaleAggregator__factory.connect(
      binance.aggregatorContract,
      bscProvider
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, chainId]);

  React.useEffect(() => {
    if (aggregator) {
      (async () => {
        const progress = await aggregator.presaleProgress();
        // setTotal(Number(progress.limit.toBigInt() / 10n ** 4n));

        const total = Number(
          process.env.REACT_APP_SALE_TOTAL_VALUE ?? SALE_TOTAL_DEFAULT
        );
        setTotal(total);
        setLocked(Number(progress.locked.toBigInt() / 10n ** 4n));
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, chainId, g2nBalance]);

  return (
    <>
      {transactionStatus !== TransactionStatus.DEFAULT && Modal}
      <section id="buy-token-content">
        <XXLBox>
          <Row className="buy-token-row-box">
            <Col
              xs={{ span: 12, offset: 0 }}
              // sm={{ span: 10, offset: 1 }}
              md={{ span: 8, offset: 2 }}
              lg={{ span: 6, offset: 3 }}
              xxl={{ span: 5, offset: 3 }}
              className="box-column"
            >
              {isMobile && status === "unavailable" ? (
                <BuyBoxWithoutMetamask />
              ) : (
                <BuyBoxWithMetamask
                  options={options}
                  paymentMethod={paymentMethod}
                  setActive={setActive}
                />
              )}
              <ProgressBar locked={locked} total={total} round={1} />
            </Col>
            <Col
              xs={{ span: 10, offset: 0 }}
              sm={{ span: 10, offset: 1 }}
              md={{ span: 6, offset: 3 }}
              lg={{ span: 4, offset: 4 }}
              xxl={{ span: 3, offset: 0 }}
              className="box-column"
            >
              <p className="top-text">
                You will be able to claim your G2N tokens to the buying wallet
                from the Claim Page. Tokens will be unlocked according to
                Vesting schedule which is described in Lightpaper
                <a href={LITEPAPER_PATH} target="_blank" rel="noreferrer">
                  [LP]
                </a>
              </p>
              <p className="bottom-text">
                Check if your address appears on the list after completing the
                transaction, click the BscScan icon in the transaction view
              </p>
            </Col>
          </Row>
        </XXLBox>
      </section>
    </>
  );
};

export default BuyTokenContent;
