import React from "react";
import { Tooltip, OverlayTrigger } from 'react-bootstrap'

interface CopyButtonProps {
  label: string,
  value: string
}

const CopyButton: React.FC<CopyButtonProps> = ({ label, value }) => {
  const [copied, setCopied] = React.useState<boolean>(false)

  const onClick = () => {
    navigator.clipboard.writeText(value);
    setCopied(true)

    const id = setTimeout(() => {
      setCopied(false);
    }, 1000)

    return () => clearTimeout(id)
  }

  const renderTooltip = (props: any) => {
    return <Tooltip id="copy-tooltip" { ...props }>
      {copied ? "Copied!" : label}
    </Tooltip>
  }

  return <OverlayTrigger overlay={renderTooltip} placement="top" delay={{ show: 200, hide: 200 }}>
    <button className="copy-button" onClick={onClick}>
      <img src="images/svg/copy.svg" alt="copy" />
    </button>
  </OverlayTrigger>
}

export default CopyButton