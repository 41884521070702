import React from "react";
import { Row, Col } from "react-bootstrap";
import XXLBox from "../../components/XXLBox";
import TokenDetail from "../../components/TokenDetail";
import CopyButton from "../../components/CopyButton";
import { configInfo } from "../../../networkInfo";
import { useConfig, WayOfBuyingG2n } from "../../../context/ConfigContext";
import { useReflinkForContract } from "../../../hook/useReflinkForContract";

const TokenDetails = () => {
  const { paymentMethod } = useConfig();
  const { nativeContractInfo, burnContractInfo } = useReflinkForContract();

  const onEtherscanLinkClicked = () => {
    if (paymentMethod === WayOfBuyingG2n.BNB) {
      return nativeContractInfo.contract;
    }
    if (paymentMethod === WayOfBuyingG2n.BURN_SKEY) {
      return burnContractInfo.contract;
    }
    throw new Error("Chaeck payment method");
  };

  return (
    <section id="token-details">
      <XXLBox id="token-details-container">
        <span id="token-details-header">Go2NFT token details</span>
        <Row style={{ rowGap: "30px", marginTop: "20px" }}>
          <Col xs={12} lg={6} className="token-detail-top-row">
            <TokenDetail label="Token name">
              <span>Go2NFT</span>
            </TokenDetail>
            <TokenDetail label="Token symbol">
              <span>G2N</span>
            </TokenDetail>
            <TokenDetail label="Decimals">
              <span>{configInfo.token.g2n.precision}</span>
            </TokenDetail>
          </Col>
          <Col xs={12} lg={6}>
            <TokenDetail label="BSC Contract address">
              <>
                <span style={{ marginRight: "10px" }}>
                  {onEtherscanLinkClicked()}
                </span>
                <CopyButton label="Copy" value={onEtherscanLinkClicked()} />
              </>
            </TokenDetail>
          </Col>
        </Row>
      </XXLBox>
    </section>
  );
};

export default TokenDetails;
