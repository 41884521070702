import LandingPage from "../UI/Pages/LandingPage";
import BuyTokenView from "../UI/Pages/BuyTokenPage";
import ClaimExamplePage from "../UI/Pages/ClaimExamplePage";
import BurnTxExamplePage from "../UI/Pages/BurnTxExamplePage";
import Partners from "../UI/Pages/Partners";

const routes: {
  id: number;
  path: string;
  Component: () => JSX.Element;
}[] = [
  // {
  //   id: 1,
  //   path: "/",
  //   Component: LandingPage
  // },
  {
    id: 1,
    path: "/",
    Component: BuyTokenView
  }
  // ,
  // {
  //   id: 3,
  //   path: "/claim-example-page",
  //   Component: ClaimExamplePage
  // },
  // {
  //   id: 4,
  //   path: "/burn_tx_example",
  //   Component: BurnTxExamplePage
  // },
  // {
  //   id: 5,
  //   path: "/partners",
  //   Component: Partners
  // }
];

export default routes;
