import React from "react";

interface ReturnedList {
  text: string;
  linkTo: string;
  active: boolean;
  setMeActive: Function;
}

export const ActiveSectionContext = React.createContext(null as any);

export const ActiveSectionProvider: React.FC = (props) => {
  const initialActiveItem = () => {
    return window.location.hash.slice(1);
  };

  const [activeSection, setActiveSection] = React.useState(initialActiveItem());

  const itemsList = [
    { text: "BUY TOKEN", linkName: "BUY TOKEN", linkTo: "/buy" },
    {
      text: "CREATE NFT",
      linkName: "CREATE NFT",
      linkTo:
        process.env.REACT_APP_GENERATOR_URL ||
        "https://g2n-mvp-dev.web.app?default=true"
    },
    { text: "About", linkName: "about", linkTo: "/#about" },
    {
      text: "Platform",
      linkName: "platform",
      linkTo: "/#platform"
    },
    {
      text: "Technology",
      linkName: "technology",
      linkTo: "/#technology"
    },
    { text: "Roadmap", linkName: "roadmap", linkTo: "/#roadmap" },
    { text: "Team", linkName: "team", linkTo: "/#team" },
    { text: "Partners", linkName: "partners", linkTo: "/partners" }
  ];

  // @krzysiek - Temporary fix
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const navItemsList = itemsList.map(
    ({ text, linkTo, linkName }): ReturnedList => {
      return {
        text,
        linkTo,
        active: linkName === activeSection,
        setMeActive: () => {
          setActiveSection(linkName);
        }
      };
    }
  );

  return (
    <ActiveSectionContext.Provider
      value={{
        itemsList,
        setActiveSection
      }}
    >
      {props.children}
    </ActiveSectionContext.Provider>
  );
};

export const useNavbarList = () => React.useContext(ActiveSectionContext);
