import { useMetaMask } from "metamask-react";
import React from "react";
import { Spinner } from "react-bootstrap";
import { BigToken } from "../../../class/BigToken";
import { useConfig } from "../../../context/ConfigContext";
import { switchNetwork } from "../../../utils/switchNetwork";

interface BnbBalanceProps {
  balance: bigint | undefined;
  nativeTokenPrice: bigint;
  isCorrectNetwork: boolean;
  loading: boolean;
}

const BnbBalance = ({
  balance,
  isCorrectNetwork,
  loading
}: BnbBalanceProps) => {
  const { networkSettings } = useConfig();
  const { ethereum } = useMetaMask();
  const bnbBalance = new BigToken("BNB", 1, balance);

  const showBnbBalance = () => {
    if (!isCorrectNetwork) {
      return (
        <button
          className="wrong-network"
          onClick={() => {
            // switchNetwork(ethereum, networkSettings)
          }}
        >
          Switch network to {networkSettings.name}
        </button>
      );
    }
    if (loading) {
      return (
        <span className="bnb-loading">
          <Spinner animation={"border"} className="spinner-img" size={"sm"} />
          {"BNB"}
        </span>
      );
    }
    if (balance) {
      return (
        <span className="bnb-balance">
          <p>{bnbBalance.totalTokensPriceInStringLong()}</p>
          <p style={{ marginLeft: "5px" }}>BNB</p>
        </span>
      );
    }
    return (
      <span className="bnb-balance">
        <p>0.00000000 BNB</p>
      </span>
    );
  };

  return <div>{showBnbBalance()}</div>;
};

export default BnbBalance;
