import React from "react";
import { TransactionStatus, useModalInfo } from "../context/ModalInfoContext";

export type BnbStatus = "empty" | "loading" | "completed";

interface UseBnbBalance {
  bscProvider: any;
  updateNativeTokenPrice: Function;
  setNativeTokenPrice: Function;
  status: string;
  account: string | null;
  isCorrectNetwork: boolean;
  isConnectionFailed: boolean;
}

const useBnbBalance = ({
  bscProvider,
  updateNativeTokenPrice,
  setNativeTokenPrice,
  status,
  account,
  isCorrectNetwork,
  isConnectionFailed
}: UseBnbBalance) => {
  const [bnbBalance, setBnbBalance] = React.useState<bigint>(0n);
  const [loadingBnb, setLoadingBnb] = React.useState(false);

  const { transactionStatus } = useModalInfo();

  const getBnbBalance = React.useCallback(
    async (account: string) => {
      const balance = await bscProvider.getBalance(account);
      const bigintBalance = balance.toBigInt();
      setLoadingBnb(false);
      return bigintBalance;
    },
    [bscProvider]
  );

  React.useEffect(() => {
    if (account && isCorrectNetwork && !isConnectionFailed) {
      (async () => {
        setLoadingBnb(true);
        setBnbBalance(await getBnbBalance(account));
        await updateNativeTokenPrice();
      })();
    } else {
      setBnbBalance(0n);
      setNativeTokenPrice(0n);
    }
  }, [
    account,
    status,
    isCorrectNetwork,
    getBnbBalance,
    updateNativeTokenPrice,
    setNativeTokenPrice,
    isConnectionFailed
  ]);

  React.useEffect(() => {
    if (account && transactionStatus === TransactionStatus.DONE) {
      (async () => {
        setBnbBalance(await getBnbBalance(account));
        await updateNativeTokenPrice();
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionStatus, account]);

  React.useEffect(() => {
    (async () => {
      await updateNativeTokenPrice();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { bnbBalance, loadingBnb };
};

export default useBnbBalance;
