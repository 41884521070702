import React from "react";

interface InitializeModalProps {
  resetStatus: Function;
}

const InitializeModal = ({ resetStatus }: InitializeModalProps) => {
  return (
    <div className="modal-wrapper">
      <div className="done-modal-top">
        <h5 className="info-title">Follow the instructions</h5>
        <img className="modal-info" src={"images/svg/info.svg"} alt="Pending" />
        <p className="info-description">Accept transaction in Metamask</p>
        <img
          role="button"
          src={"images/svg/close-cross.svg"}
          alt="close"
          className="cross"
          onClick={() => resetStatus()}
        />
      </div>
    </div>
  );
};

export default InitializeModal;
