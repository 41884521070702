interface FooterSocialMediumProps {
  name: string
  url: string
  iconName: string
}

const FooterSocialMedium: React.FC<FooterSocialMediumProps> = ({ name, url, iconName }) => {
  const handleClick = () => {
    window.open(url, '_blank');
  }

  return <div className="footer-social-medium" onClick={handleClick}>
    <img className="footer-social-medium-icon" src={`images/svg/social-media/${iconName}.svg`} width="18px" height="18px" alt={name} />
    <span className="footer-social-medium-name">{name}</span>
  </div>
}

export default FooterSocialMedium