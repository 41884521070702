import React from "react";
import { binance, defaultNetwork, ethereum, Network } from "../networkInfo";

const ConfigContext = React.createContext(null as any);

export enum WayOfBuyingG2n {
  BNB = "BNB",
  BURN_SKEY = "Skey"
}

export const ConfigProvider: React.FC = (props) => {
  const buyWayOptions = [
    {
      name: WayOfBuyingG2n.BNB,
      isActive: true
    },
    {
      name: WayOfBuyingG2n.BURN_SKEY,
      isActive: false
    }
  ];

  const [networkSettings, setNetworkSettings] =
    React.useState<Network>(defaultNetwork);
  const [paymentMethod, setPaymentMethod] = React.useState<WayOfBuyingG2n>(
    WayOfBuyingG2n.BNB
  );

  const setActive = (name: WayOfBuyingG2n) => {
    setPaymentMethod(name);
  };

  const getNetwork = (wayOfBuyingG2n: WayOfBuyingG2n) => {
    if (wayOfBuyingG2n === WayOfBuyingG2n.BNB) {
      setNetworkSettings(binance);
      return;
    }
    if (wayOfBuyingG2n === WayOfBuyingG2n.BURN_SKEY) {
      setNetworkSettings(ethereum);
      return;
    }

    throw new Error(
      `There is no such way of buying token like: ${wayOfBuyingG2n}`
    );
  };

  React.useEffect(() => {
    getNetwork(paymentMethod);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethod]);

  return (
    <ConfigContext.Provider
      value={{
        networkSettings,
        options: [...buyWayOptions],
        paymentMethod,
        setActive
      }}
    >
      {props.children}
    </ConfigContext.Provider>
  );
};

export const useConfig = () => React.useContext(ConfigContext);
