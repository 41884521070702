import React from "react";

import { weiToFloat, floatToWei } from "../utils/balanceConvertors";

const ORACLE_URL =
  "https://gbsc.blockscan.com/gasapi.ashx?apikey=key&method=gasoracle";

const NATIVE_TOKEN_PRECISION = 18;
const USDT_PRECISION = 8;

type FetchStatus = "init" | "fetched" | "error";

interface OracleResponse {
  status: string;
  message: string;
  result: {
    LastBlock: string;
    SafeGasPrice: string;
    ProposeGasPrice: string;
    FastGasPrice: string;
    UsdPrice: string;
  };
}

interface Prices {
  safePrice: bigint;
  proposedPrice: bigint;
  fastPrice: bigint;
}

interface GasPriceContextState {
  loading: boolean;
  status: FetchStatus;
  fetchCurrentPrice: () => void;
  convertFeeToUsd: (gasUnitsLimit: number, gasFee: bigint) => number;
  usdPrice?: number;
  prices?: Prices;
}

const GasPriceContext = React.createContext<GasPriceContextState>(null as any);

export const GasPriceProvider: React.FC = (props) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [status, setStatus] = React.useState<FetchStatus>("init");
  const [prices, setPrices] = React.useState<Prices>();
  const [usdPrice, setUsdPrice] = React.useState<number>();

  const parsePrice = (input: string): bigint => {
    const price = parseFloat(input);

    return floatToWei(price, 9);
  };

  const convertWeiToUsd = (
    weiAmount: bigint,
    weiDecimals: number,
    usdPrice: number,
    usdDecimals: number
  ): number => {
    const usdWeiPrice = floatToWei(usdPrice, usdDecimals);

    const usdWeiAmount =
      (weiAmount * usdWeiPrice) / BigInt(10n) ** BigInt(weiDecimals);

    return weiToFloat(usdWeiAmount, usdDecimals);
  };

  const calculateWeiFee = (gasUnitsLimit: number, gasPrice: bigint): bigint => {
    return BigInt(gasUnitsLimit) * gasPrice;
  };

  const convertFeeToUsd = React.useCallback(
    (gasUnitsLimit: number, gasPrice: bigint): number => {
      if (!usdPrice) {
        throw new Error("Current USD price unknown");
      }
      const fee = calculateWeiFee(gasUnitsLimit, gasPrice);

      return convertWeiToUsd(
        fee,
        NATIVE_TOKEN_PRECISION,
        usdPrice,
        USDT_PRECISION
      );
    },
    [usdPrice]
  );

  const fetchCurrentPrice = React.useCallback(() => {
    setLoading(true);
    fetch(ORACLE_URL)
      .then((res) => res.json())
      .then((data: OracleResponse) => {
        if (data.message !== "OK") {
          setStatus("error");
          return;
        }
        setStatus("fetched");
        setPrices({
          safePrice: parsePrice(data.result.SafeGasPrice),
          proposedPrice: parsePrice(data.result.ProposeGasPrice),
          fastPrice: parsePrice(data.result.FastGasPrice)
        });
        setUsdPrice(parseFloat(data.result.UsdPrice));
      })
      .catch((e) => {
        console.error("Error fetching gas prices: ", e);
        setStatus("error");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  React.useEffect(() => {
    fetchCurrentPrice();
  }, [fetchCurrentPrice]);

  return (
    <GasPriceContext.Provider
      value={{
        loading,
        status,
        prices,
        usdPrice,
        convertFeeToUsd,
        fetchCurrentPrice
      }}
    >
      {props.children}
    </GasPriceContext.Provider>
  );
};

export const useGasPrice = () => React.useContext(GasPriceContext);
