import { binance } from "../networkInfo";

const token = {
  address: binance.g2nToken,
  symbol: "G2N",
  decimals: 4,
  image: `${window.location.origin}/images/png/G2N_Metamask_256x256.png`
};

const addTokenToMetamask = async () => {
  // eslint-disable-next-line
  const ethereum = (window as any).ethereum;
  if (!ethereum) {
    alert("Metamask is not installed");
    return;
  }

  try {
    await ethereum.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20",
        options: {
          address: token.address,
          symbol: token.symbol,
          decimals: token.decimals,
          image: token.image
        }
      }
    });
  } catch (error) {
    console.error(error);
  }
};

export default addTokenToMetamask;
