import React from "react";
import addTokenToMetamask from "../../../utils/addTokenToMetamask";
import { Row, Col } from "react-bootstrap";
import ActionColumn from "../../components/ActionColumn";
import XXLBox from "../../components/XXLBox";
import { useBuyToken } from "../../../context/BuyTokenContext";
import { useConfig, WayOfBuyingG2n } from "../../../context/ConfigContext";
import { useReflinkForContract } from "../../../hook/useReflinkForContract";

const MetamaskActions = () => {
  const { status, connect: connectMetamask } = useBuyToken();
  const isMetamaskConnected = React.useMemo(() => {
    return status === "connected";
  }, [status]);
  const { networkSettings, paymentMethod } = useConfig();
  const { nativeContractInfo, burnContractInfo } = useReflinkForContract();

  const onEtherscanLinkClicked = () => {
    const { scannerUrl } = networkSettings;
    if (paymentMethod === WayOfBuyingG2n.BNB) {
      window.open(
        `${scannerUrl}/address/${nativeContractInfo.contract}`,
        "_blank"
      );
    }
    if (paymentMethod === WayOfBuyingG2n.BURN_SKEY) {
      window.open(
        `${scannerUrl}/address/${burnContractInfo.contract}`,
        "_blank"
      );
    }
  };

  const actions = React.useMemo(
    () => [
      {
        icon: "add-g2n-token.svg",
        title: "Add G2N Token",
        description:
          "Add Go2NFT Token address to MetaMask or\nanother wallet to make sure your Go2NFT tokens\nare visible in the list of assets.",
        buttonDisabled: !isMetamaskConnected,
        buttonLabel: !isMetamaskConnected
          ? "Connect Metamask"
          : "Add G2N Token",
        onClick: addTokenToMetamask,
        color: "#1b1a17"
      },
      {
        icon: "transfer-bnb.svg",
        title: "Transfer BNB",
        description:
          "You are able to buy G2N tokens by transfer selected amount of BNB to crowdsale address",
        buttonDisabled: false,
        buttonLabel: "Go to Bsc Scan",
        onClick: onEtherscanLinkClicked,
        color: "#1b1a17"
      },
      {
        icon: "metamask.svg",
        title: "Use Metamask",
        description:
          "Remember:\n1. DO NOT send any other tokens than BNB to crowdsale address\n2. DO NOT send BNB directly from exchange",
        buttonLabel: isMetamaskConnected
          ? "Metamask Connected"
          : "Connect Metamask",
        buttonDisabled: isMetamaskConnected,
        onClick: connectMetamask,
        color: "#f03535"
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [connectMetamask, isMetamaskConnected]
  );

  return (
    <section id="buy-actions">
      <XXLBox id="buy-actions-container">
        <div className="action-separator" />
        <Row className="buy-actions-list">
          {actions.map(
            ({
              icon,
              title,
              description,
              buttonLabel,
              onClick,
              buttonDisabled,
              color
            }) => (
              <Col
                xs={12}
                lg={4}
                className="action-column-container"
                key={title}
              >
                <ActionColumn
                  iconSrc={`images/svg/actions/${icon}`}
                  title={title}
                  description={description}
                  buttonDisabled={buttonDisabled}
                  buttonLabel={buttonLabel}
                  onClick={onClick}
                  color={color}
                />
              </Col>
            )
          )}
        </Row>
      </XXLBox>
    </section>
  );
};

export default MetamaskActions;

// https://bscscan.com/address/0x1991f31a1eA1dFd02502883E56033EE6381d4C38
