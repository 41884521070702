import React from "react";
import { Spinner } from "react-bootstrap";

interface BuyButtonProps {
  isDisabled: boolean;
  status: string;
  onClick?: Function;
}

const BuyButton = ({ isDisabled, onClick, status }: BuyButtonProps) => {
  const isLoading = (status: string) => {
    return false;
  };

  return (
    <button
      disabled={isDisabled}
      onClick={() => {}}
      className="buy-token-button"
    >
      {isLoading(status) && <Spinner animation={"border"} />}
      Buy
    </button>
  );
};

export default BuyButton;
