import React from "react";
import { WayOfBuyingG2n } from "../../../context/ConfigContext";
import InputGroup from "./InputGroup";
// import WayOfBuySelect from "./WayOfBuySelect";

interface BuyBoxWithMetamaskProps {
  options: any[];
  paymentMethod: WayOfBuyingG2n;
  setActive: Function;
}

const BuyBoxWithMetamask = ({
  options,
  paymentMethod,
  setActive
}: BuyBoxWithMetamaskProps) => {
  return (
    <>
      <div className="transfer-box">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h6 className="box-title">Buy G2N Tokens</h6>
          {/* <WayOfBuySelect
            options={options}
            paymentMethod={paymentMethod}
            setActive={setActive}
          /> */}
        </div>
        <InputGroup />
      </div>
    </>
  );
};

export default BuyBoxWithMetamask;
