import React from "react";

interface PendingModalProps {
  info: string;
  resetStatus: Function;
  url: string;
}

const PendingModal = ({ info, resetStatus, url }: PendingModalProps) => {
  return (
    <div className="modal-wrapper">
      <div className="done-modal-top">
        <h5 className="done-title pending">Please wait. It may take a while</h5>
        <img
          className="pending-spinner"
          src={"images/svg/spinner.svg"}
          alt="Pending"
        />
        <p className="pending-info">Transaction in progress.</p>
        <h5 className="pending-hash-title">Tx Hash</h5>
        <p className="pending-view">view on bscscan</p>
        <a className="pending-hash" href={url} target="_blank" rel="noreferrer">
          {info}
        </a>
        <img
          role="button"
          src={"images/svg/close-cross.svg"}
          alt="close"
          className="cross"
          onClick={() => resetStatus()}
        />
      </div>
    </div>
  );
};

export default PendingModal;
