import { pickDefaultNetwork, pickNetworkStageFromConfig } from "./utils/utils";

export enum NetworkStages {
  TESTNET = "testnet",
  MAINNET = "mainnet"
}

export enum Networks {
  BSC = "binanceSmartChain",
  ETHEREUM = "ethereum"
}

export const configInfo = Object.freeze({
  networks: {
    testnet: {
      binance: {
        url: "https://data-seed-prebsc-1-s1.binance.org:8545/",
        chainId: "0x61",
        name: "SmartChain - Testnet",
        scannerUrl: "https://testnet.bscscan.com",
        presaleContract: {
          default: {
            contract: "0x3B67AEc63868c2570CCdCda18178489D92b5e6bF",
            g2nPrice: 10000000n
          },
          abcd: {
            contract: "0x76e71329E0588f78e5591B2b9389022Ac09fa92E",
            g2nPrice: 8000000n
          }
        },
        aggregatorContract: "0x11aC721f4b54520a25a214123015Da74CC8d50D8",
        burnPresale: {
          default: {
            contract: "0x650DB385cf73F1d0ef32aBbacE2558dA5FC2beC9",
            g2nPrice: 1000000n
          },
          aaaa: {
            contract: "0xd5DC56e0BB380D7ECB3A576B3eeE9BA8B23dD66e",
            g2nPrice: 800000n
          }
        },
        skeyToken: "0x52ee6E8c0d55DE2FCAE0faF760d01269820895aA", //UNUSED
        g2nToken: "0xaaC780f8BAFBA59dfE824D050B51229cead450EE",
        gasAmount: 70000n,
        nativeToken: "BNB",
        nativeDecimals: 18,
        burnOracle: "0x79478189F09dCd3bf4548CFe12e4df3256dFE4E3",
        oracleUrl:
          "https://us-central1-g2n-oracle-presale-proxy-dev.cloudfunctions.net/getUpdateTimestamp"
      },
      ethereum: {
        url: "https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
        chainId: "0x4",
        name: "Rinkeby - Testnet",
        scannerUrl: "https://rinkeby.etherscan.io",
        skeyToken: "0x52ee6E8c0d55DE2FCAE0faF760d01269820895aA",
        nativeToken: "ETH",
        nativeDecimals: 18
      }
    },
    mainnet: {
      binance: {
        url: "https://bsc-dataseed1.binance.org",
        chainId: "0x38",
        name: "Binance Smart Chain Mainnet",
        scannerUrl: "https://bscscan.com",
        presaleContract: {
          default: {
            contract: "0x1991f31a1eA1dFd02502883E56033EE6381d4C38",
            g2nPrice: 1000000n
          },
          "747859557": {
            contract: "0x5542D4bC034c81C8F6fDDC4dCaa258a3037028Ec",
            g2nPrice: 1000000n
          }
          // dcba: {
          //   contract: "nskjdgnjksgnjksdgnkjsgnkjdgsnjkdgsjnksdg",
          //   g2nPrice: 600000n
          // }
        },
        aggregatorContract: "0x67988e4e78c74D3c66308A66f57D6E7fa18eb8c3",
        burnPresale: {
          default: {
            contract: "0x0000000000000000000000000000000000000000", //WRONG
            g2nPrice: 1000000n
          }
        },
        skeyToken: "0x06A01a4d579479Dd5D884EBf61A31727A3d8D442",
        g2nToken: "0x079A304566A3aD72a3Dd72aB79c9724734783C2a",
        gasAmount: 70000n,
        nativeToken: "BNB",
        nativeDecimals: 18,
        burnOracle: "0x0000000000000000000000000000000000000000",
        oracleUrl:
          "https://us-central1-g2n-oracle-presale-proxy-dev.cloudfunctions.net/getUpdateTimestamp"
      },
      ethereum: {
        url: "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
        chainId: "0x01",
        name: "Ethereum Mainnet",
        scannerUrl: "https://etherscan.io",
        skeyToken: "0x06A01a4d579479Dd5D884EBf61A31727A3d8D442",
        nativeToken: "ETH",
        nativeDecimals: 18
      }
    }
  },
  token: {
    skey: {
      tokenAddress: "0x06A01a4d579479Dd5D884EBf61A31727A3d8D442",
      precision: 8
    },
    usdt: {
      tokenAddress: "0x945be248f667260b8733Da2E29b5A3F0FD02AAa9",
      precision: 6
    },
    g2n: {
      tokenAddress: "0xaaC780f8BAFBA59dfE824D050B51229cead450EE",
      precision: 4
    },
    bnb: {
      tokenAddress: "0xB8c77482e45F1F44dE1745F52C74426C631bDD52",
      precision: 18
    }
  }
});

export type Config = typeof configInfo;
export type NetworkConfig =
  | Config["networks"]["mainnet"]
  | Config["networks"]["testnet"];

export const socials = {
  telegram: "http://t.me/go2nft",
  twitter: "https://twitter.com/go2nft_official",
  instagram: "https://www.instagram.com/go2nft/"
};

export const networkStage: NetworkConfig = pickNetworkStageFromConfig(
  (process.env.REACT_APP_STAGE as NetworkStages) ?? NetworkStages.TESTNET,
  configInfo
);

export const defaultNetwork = pickDefaultNetwork(networkStage);

export type Binance = NetworkConfig["binance"];
export type Ethereum = NetworkConfig["ethereum"];
export type Network = Binance | Ethereum;
export type BurnContractList = Binance["burnPresale"];
export type NormalContractList = Binance["presaleContract"];
export type Contract = {
  contract: string;
  g2nPrice: bigint;
};

export const binance: Binance = { ...networkStage.binance };
export const ethereum: Ethereum = { ...networkStage.ethereum };

export const oracleOptions = {
  url: binance.oracleUrl,
  interval: 30000,
  timeout: 60000
};
