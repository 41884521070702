import React from "react";
import ModalInfo from "../UI/components/ModalInfo";

export enum TransactionStatus {
  DEFAULT = "",
  ORACLE_DEAD = "ORACLE_DEAD",
  ORACLE_LOADING = "ORACLE_LOADING",
  INITIALIZED = "INITIALIZED",
  CONFIRM_TRANSACTION = "CONFIRM_TRANSACTION",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
  ERROR = "ERROR",
  DONE = "DONE"
}

export enum ModalToOpen {
  VIDEO_MODAL = "VIDEO_MODAL"
}

interface ModalInfoProps {
  transactionStatus: TransactionStatus | ModalToOpen;
  setTransactionStatus: Function;
  setInfoMessage: Function;
  Modal: React.ReactNode;
}

export const ModalInfoContext = React.createContext<ModalInfoProps>(
  null as any
);

export const ModalInfoProvider: React.FC = (props) => {
  const [transactionStatus, setTransactionStatus] = React.useState<
    TransactionStatus | ModalToOpen
  >(TransactionStatus.DEFAULT);

  const [Modal, setModal] = React.useState<React.ReactNode>();
  const [infoMessage, setInfoMessage] = React.useState("");

  React.useEffect(() => {
    setModal(
      <ModalInfo
        status={transactionStatus}
        info={infoMessage}
        setTransactionStatus={setTransactionStatus}
      />
    );
  }, [transactionStatus, infoMessage]);

  return (
    <ModalInfoContext.Provider
      value={{
        transactionStatus,
        setTransactionStatus,
        setInfoMessage,
        Modal
      }}
    >
      {props.children}
    </ModalInfoContext.Provider>
  );
};

export const useModalInfo = () => React.useContext(ModalInfoContext);
