import React from "react";
import { useBuyToken } from "../../../context/BuyTokenContext";
import { WayOfBuyingG2n } from "../../../context/ConfigContext";
import { useGasPrice } from "../../../context/GasPriceContext";
import {
  TransactionStatus,
  useModalInfo
} from "../../../context/ModalInfoContext";
import { floatToWei } from "../../../utils/balanceConvertors";
import ConfirmModalBNB from "../../components/ConfirmModalBNB";
import BnbInput from "./BnbInput";
import BuyButton from "./BuyButton";
import G2nConvertRatioBNB from "./G2nConvertRatioBNB";
import G2nConvertRatioSkey from "./G2nConvertRatioSkey";
import Input from "./Input";
import SkeyInput from "./SkeyInput";
import BnbInDollars from "./BnbInDollars";
import SkeyInDollars from "./SkeyInDollars";
import ConfirmModalSkey from "./ConfirmModalSkey";
import { BigToken } from "../../../class/BigToken";
import { BigTokenConverter } from "../../../class/BigTokenConverter";
import { InputValidator } from "../../../class/InputValidator";
// import { useOracleStatus } from "../../../hook/useOracleStatus";
// import { oracleOptions } from "../../../networkInfo";
import usePresale from "../../../hook/usePresale";
import { BigNumber } from "ethers";
import useG2nPrice from "../../../hook/useG2nPrice";

const InputGroup = () => {
  const {
    isConnectionFailed,
    buyToken,
    burnSkey,
    isCorrectNetwork,
    bnbBalance,
    g2nBalance,
    skeyBalance,
    loadingG2n,
    loadingBnb,
    loadingSkey,
    nativeTokenPrice,
    networkSettings,
    paymentMethod,
    skeyTokenPrice,
    account
  } = useBuyToken();
  const { setTransactionStatus, transactionStatus, setInfoMessage } =
    useModalInfo();
  const { prices } = useGasPrice();
  // const { oracleStatus } = useOracleStatus(oracleOptions);
  const { burnPresale } = usePresale();

  const [isConfirmActive, setIsConfirmActive] = React.useState(false);
  const [bnbInput, setBnbInput] = React.useState({
    touched: false,
    value: "",
    error: ""
  });

  const [g2NInput, setG2NInput] = React.useState({
    touched: false,
    value: "",
    error: ""
  });

  const [skeyInput, setSkeyInput] = React.useState({
    touched: false,
    value: "",
    error: ""
  });

  const { nativeG2nPrice, burnG2nPrice } = useG2nPrice();

  const BnbBalance = new BigToken("BNB", 1, bnbBalance);
  const SkeyBalance = new BigToken("Skey", 1, skeyBalance);
  const G2NBalance = () => {
    if (paymentMethod === WayOfBuyingG2n.BNB) {
      return new BigToken("G2N", g2nBalance, nativeG2nPrice);
    }
    if (paymentMethod === WayOfBuyingG2n.BURN_SKEY) {
      return new BigToken("G2N", g2nBalance, burnG2nPrice);
    }
    throw new Error("Wrong payment type");
  };

  const converter = new BigTokenConverter();

  const writeToBnbInput = (value: string): void => {
    const bnb = new BigToken("BNB", value, nativeTokenPrice);
    const g2n = new BigToken("G2N", 1, nativeG2nPrice);
    const validator = new InputValidator(
      value,
      "BNB",
      new BigToken("BNB", bnbBalance, 1),
      nativeTokenPrice,
      g2n,
      G2NBalance()
    );
    const errorMessage = validator.validate();
    if (errorMessage) {
      setBnbInput({ ...bnbInput, error: errorMessage, value });
      setG2NInput({ ...g2NInput, value: "", error: "" });
      setSkeyInput({ ...skeyInput, value: "", error: "" });
    } else {
      setBnbInput({ ...bnbInput, value, error: "" });
      setSkeyInput({ ...skeyInput, value: "", error: "" });
      setG2NInput({
        ...g2NInput,
        value: converter.convertTokensBasedOnPrice(bnb, g2n)
      });
    }
  };

  const writeToG2NInput = (value: string): void => {
    let errorMessage;
    const g2nNative = new BigToken("G2N", value, nativeG2nPrice);
    const g2nBurn = new BigToken("G2N", value, burnG2nPrice);
    const bnb = new BigToken("BNB", 1, nativeTokenPrice);
    const skey = new BigToken("Skey", 1, skeyTokenPrice);

    if (paymentMethod === WayOfBuyingG2n.BNB) {
      const validator = new InputValidator(
        value,
        "G2N",
        G2NBalance(),
        nativeG2nPrice,
        bnb,
        new BigToken("BNB", bnbBalance, 1)
      );
      errorMessage = validator.validate();
    }
    if (paymentMethod === WayOfBuyingG2n.BURN_SKEY) {
      const validator = new InputValidator(
        value,
        "G2N",
        G2NBalance(),
        burnG2nPrice,
        skey,
        new BigToken("Skey", skeyBalance, 1)
      );
      errorMessage = validator.validate();
    }

    if (errorMessage) {
      setG2NInput({ ...g2NInput, value, error: errorMessage });
      setSkeyInput({ ...skeyInput, value: "", error: "" });
      setBnbInput({ ...bnbInput, value: "", error: "" });
      return;
    }
    setG2NInput({ ...g2NInput, value, error: "" });

    if (paymentMethod === WayOfBuyingG2n.BNB) {
      setBnbInput({
        ...bnbInput,
        value: converter.convertTokensBasedOnPrice(g2nNative, bnb),
        error: ""
      });
      return;
    }
    if (paymentMethod === WayOfBuyingG2n.BURN_SKEY) {
      setSkeyInput({
        ...skeyInput,
        value: converter.convertTokensBasedOnPrice(g2nBurn, skey),
        error: ""
      });
      return;
    }
  };

  const writeToSkeyInput = (value: string): void => {
    const skey = new BigToken("Skey", value, skeyTokenPrice);
    const g2n = new BigToken("G2N", 1, burnG2nPrice);
    const validator = new InputValidator(
      value,
      "Skey",
      new BigToken("Skey", skeyBalance, 1),
      skeyTokenPrice ?? 0n,
      g2n,
      G2NBalance()
    );
    const errorMessage = validator.validate();

    if (errorMessage) {
      setSkeyInput({ ...skeyInput, error: errorMessage, value });
      setBnbInput({ ...bnbInput, error: "", value: "" });
      setG2NInput({ ...g2NInput, value: "", error: "" });
    } else {
      setSkeyInput({ ...skeyInput, value, error: "" });
      setBnbInput({ ...bnbInput, value: "", error: "" });
      setG2NInput({
        ...g2NInput,
        value: converter.convertTokensBasedOnPrice(skey, g2n),
        error: ""
      });
    }
  };

  const isInputDisabled = (): boolean => {
    if (paymentMethod === WayOfBuyingG2n.BNB) {
      return !bnbBalance || !nativeTokenPrice || !isCorrectNetwork;
    }
    if (paymentMethod === WayOfBuyingG2n.BURN_SKEY) {
      return (
        !skeyBalance || !skeyTokenPrice || !isCorrectNetwork
        // || oracleStatus !== "active"
      );
    }

    return true;
  };

  const isBuyDisabled = (): boolean => {
    if (paymentMethod === WayOfBuyingG2n.BNB) {
      return (
        !account ||
        isConnectionFailed ||
        Boolean(bnbInput.error) ||
        Boolean(g2NInput.error) ||
        bnbInput.value === "" ||
        g2NInput.value === "" ||
        transactionStatus !== TransactionStatus.DEFAULT ||
        isConfirmActive
      ); //To EXTEND
    } else if (paymentMethod === WayOfBuyingG2n.BURN_SKEY) {
      return (
        !account ||
        isConnectionFailed ||
        Boolean(g2NInput.error) ||
        Boolean(skeyInput.error) ||
        skeyInput.value === "" ||
        g2NInput.value === "" ||
        transactionStatus !== TransactionStatus.DEFAULT ||
        isConfirmActive
        // || oracleStatus !== "active"
      ); /*To EXTEND*/
    }

    throw new Error("Check payment method");
  };

  const handleModalControl = (status: TransactionStatus, info: string) => {
    setTransactionStatus(status);
    setInfoMessage(info);
  };

  const showConfirmModal = () => {
    setIsConfirmActive(true);
  };

  const resetConfirmModal = () => {
    setIsConfirmActive(false);
  };

  const subscribeToTransaction = () => {
    const filter = burnPresale.filters.Buy(account);
    burnPresale.on(
      filter,
      (address: string, value: BigNumber, transaction: any) => {
        handleModalControl(TransactionStatus.DONE, transaction.transactionHash);
        burnPresale.removeAllListeners();
      }
    );
  };

  // const unsubscribeFromTransaction = () => {
  //   burnPresale.removeListener(filter, callback);
  // };

  const handleBuyButtonClicked = () => {
    // calculate amount of BNB to transfer
    const bnbWeiValue = floatToWei(parseFloat(bnbInput.value), 18);
    handleModalControl(
      TransactionStatus.INITIALIZED,
      "Transaction initialized"
    );

    //buy the token via BNB
    buyToken(bnbWeiValue)
      .then(async (txResponse) => {
        // transaction has been broadcasted
        // now wait for confirmation on the blockchain
        handleModalControl(TransactionStatus.PENDING, txResponse.hash);
        return await txResponse.wait(2);
      })
      .then((receipt) => {
        // transaction is confirmed
        handleModalControl(TransactionStatus.DONE, receipt.transactionHash);
      })
      .catch((e) => {
        //transaction has failed
        if (e.code === -32015) {
          handleModalControl(
            TransactionStatus.ERROR,
            "Check network connection"
          );
        } else if (e.code === 4001) {
          handleModalControl(
            TransactionStatus.REJECTED,
            "Transaction rejected"
          );
        } else {
          handleModalControl(TransactionStatus.ERROR, e.message);
        }
      });
    resetConfirmModal();
  };

  const handleBurnSkey = async () => {
    handleModalControl(
      TransactionStatus.INITIALIZED,
      "Transaction initialized"
    );

    //Start of transaction
    burnSkey(skeyInput.value)
      .then(async (transactionData: any) => {
        handleModalControl(TransactionStatus.PENDING, transactionData.hash);
        return transactionData;
      })
      .then(() => {
        subscribeToTransaction();
        return;
      })
      .catch((e: any) => {
        handleModalControl(TransactionStatus.ERROR, e.message);
      });
    resetConfirmModal();
  };

  React.useEffect(() => {
    if (transactionStatus !== TransactionStatus.DEFAULT) return;
    setBnbInput({ ...bnbInput, value: "", error: "" });
    setG2NInput({ ...g2NInput, value: "", error: "" });
    setSkeyInput({ ...skeyInput, value: "", error: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethod, transactionStatus]);
  const bnb = new BigToken("BNB", bnbInput.value, nativeTokenPrice);
  const skey = new BigToken("Skey", skeyInput.value, skeyTokenPrice);
  const g2nPresale = new BigToken("G2N", g2NInput.value, nativeG2nPrice);
  const g2nBurn = new BigToken("G2N", g2NInput.value, burnG2nPrice);

  return (
    <>
      {isConfirmActive && paymentMethod === WayOfBuyingG2n.BNB && (
        <ConfirmModalBNB
          resetStatus={resetConfirmModal}
          confirm={handleBuyButtonClicked}
          bnbValue={bnbInput.value}
          g2nValue={g2NInput.value}
          g2nCost={converter.convertTokensBasedOnPrice(
            bnb.getSingleToken(),
            g2nPresale
          )}
          gasPrice={prices?.fastPrice}
          networkSettings={networkSettings}
        />
      )}
      {isConfirmActive && paymentMethod === WayOfBuyingG2n.BURN_SKEY && (
        <ConfirmModalSkey
          resetStatus={resetConfirmModal}
          confirm={handleBurnSkey}
          skeyValue={skeyInput.value}
          g2nValue={g2NInput.value}
          g2nCost={converter.convertTokensBasedOnPrice(
            skey.getSingleToken(),
            g2nBurn
          )}
          gasPrice={prices?.fastPrice}
          networkSettings={networkSettings}
          skeyValueBigInt={skey.toBigInt()}
          skeyUnitPrice={skeyTokenPrice!}
        />
      )}
      {paymentMethod === WayOfBuyingG2n.BNB && (
        <BnbInput
          balanceForBNB={BnbBalance.totalTokensPriceInStringLong()}
          bnbInput={{
            value: bnbInput.value,
            touched: bnbInput.touched,
            error: bnbInput.error
          }}
          isCorrectNetwork={isCorrectNetwork}
          isInputDisabled={isInputDisabled}
          writeToBnbInput={writeToBnbInput}
          loadingBnb={loadingBnb}
          setBnbInput={setBnbInput}
        />
      )}
      {paymentMethod === WayOfBuyingG2n.BURN_SKEY && (
        <SkeyInput
          balanceForSkey={SkeyBalance.totalTokensPriceInStringLong()}
          skeyInput={{
            value: skeyInput.value,
            touched: skeyInput.touched,
            error: skeyInput.error
          }}
          isCorrectNetwork={isCorrectNetwork}
          isInputDisabled={isInputDisabled}
          writeToSkeyInput={writeToSkeyInput}
          loadingSkey={loadingSkey}
          setSkeyInput={setSkeyInput}
        />
      )}

      <img
        src={"images/svg/double-arrow.svg"}
        alt="double-arrow"
        height={"34px"}
        className="double-arrow"
      />
      <Input
        className={"g2n-input crypto-input"}
        inputName={"G2N"}
        balance={G2NBalance().getReducedTokensAmount()}
        value={g2NInput.value}
        touched={g2NInput.touched}
        error={g2NInput.error}
        isCorrectNetwork={isCorrectNetwork}
        placeholder={"G2N to get"}
        isDisabled={isInputDisabled()}
        setNewValue={writeToG2NInput}
        loading={loadingG2n}
        click={() => setG2NInput({ ...g2NInput, touched: true })}
      />
      <div className="prices">
        {paymentMethod === WayOfBuyingG2n.BNB && (
          <BnbInDollars priceInDollars={bnb.totalTokensPriceInStringShort()} />
        )}
        {paymentMethod === WayOfBuyingG2n.BURN_SKEY && (
          <SkeyInDollars
            priceInDollars={skey.totalTokensPriceInStringShort()}
          />
        )}
        {paymentMethod === WayOfBuyingG2n.BNB && (
          <G2nConvertRatioBNB
            howMuchG2nToGet={converter.convertTokensBasedOnPrice(
              bnb.getSingleToken(),
              g2nPresale
            )}
          />
        )}
        {paymentMethod === WayOfBuyingG2n.BURN_SKEY && (
          <G2nConvertRatioSkey
            howMuchG2nToGet={converter.convertTokensBasedOnPrice(
              skey.getSingleToken(),
              g2nBurn
            )}
          />
        )}
      </div>
      <BuyButton
        isDisabled={isBuyDisabled()}
        onClick={showConfirmModal}
        status={"connected"}
      />
      <a
        href={"https://www.youtube.com/watch?v=yJtra3wLi_0"}
        target="_blank"
        rel="noreferrer"
        className="watch-how-to-buy"
      >
        Watch how to buy on YouTube
      </a>
    </>
  );
};

export default InputGroup;
