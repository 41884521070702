import { BigToken, Token } from "./BigToken";

export class BigTokenConverter {
  convertTokensBasedOnPrice(tokensIn: BigToken, tokensOut: BigToken) {
    return (
      Number(tokensIn.totalTokensPriceInBigInt()) /
      Number(tokensOut.getSingleToken().totalTokensPriceInBigInt())
    ).toFixed(tokensOut.fixed);
  }

  convertTokensBasedOnPriceBigInt(
    tokensIn: BigToken,
    tokensOut: BigToken,
    tokenOutType: Token
  ): bigint {
    const convertRatio =
      tokensIn.totalTokensPriceInNumber() /
      tokensOut.getSingleToken().totalTokensPriceInNumber();

    return new BigToken(tokenOutType, convertRatio, 1).toBigInt();
  }
}
