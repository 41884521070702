import { useMetaMask } from "metamask-react";
import React from "react";
import { Spinner } from "react-bootstrap";
import { BigToken } from "../../../class/BigToken";
import { useConfig } from "../../../context/ConfigContext";
import { switchNetwork } from "../../../utils/switchNetwork";

interface SkeyBalanceProps {
  balance: bigint | undefined;
  skeyUnitPrice: bigint | null;
  isCorrectNetwork: boolean;
  loading: boolean;
}

const SkeyBalance = ({
  balance,
  isCorrectNetwork,
  loading
}: SkeyBalanceProps) => {
  const { networkSettings } = useConfig();
  const { ethereum } = useMetaMask();
  const skeyBalance = new BigToken("Skey", 1, balance);

  const showSkeyBalance = () => {
    if (!isCorrectNetwork) {
      return (
        <button
          className="wrong-network"
          onClick={() => switchNetwork(ethereum, networkSettings)}
        >
          Switch network to {networkSettings.name}
        </button>
      );
    }
    if (loading) {
      return (
        <span className="skey-loading">
          <Spinner animation={"border"} className="spinner-img" size={"sm"} />
          {"Skey"}
        </span>
      );
    }
    if (balance) {
      return (
        <span className="bnb-balance">
          <p>{skeyBalance.totalTokensPriceInStringShort()}</p>
          <p style={{ marginLeft: "5px" }}>Skey</p>
        </span>
      );
    }
    return (
      <span className="skey-balance">
        <p>0.00000000 Skey</p>
      </span>
    );
  };

  return <div>{showSkeyBalance()}</div>;
};

export default SkeyBalance;
