import React from "react";
import { TransactionStatus, useModalInfo } from "../context/ModalInfoContext";
import usePresale from "./usePresale";
import useProvider from "./useProvider";
import useToken from "./useToken";

interface UseSkeyBalance {
  account: string | null;
  status: string;
  isCorrectNetwork: boolean;
  isConnectionFailed: boolean;
}

const useSkeyBalance = ({
  account,
  status,
  isCorrectNetwork,
  isConnectionFailed
}: UseSkeyBalance) => {
  const [skeyBalance, setSkeyBalance] = React.useState<bigint>(0n);
  const [loadingSkey, setLoadingSkey] = React.useState(false);

  const { transactionStatus } = useModalInfo();
  const { bscProvider, ethProvider } = useProvider();
  const { skeyToken } = useToken({ bscProvider, ethProvider });
  const { setNativeTokenPrice } = usePresale();

  const getSkeyBalance = React.useCallback(
    async (account: string) => {
      const localSkeyBalance = (await skeyToken.balanceOf(account)).toBigInt();
      setLoadingSkey(false);
      return localSkeyBalance;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [skeyToken]
  );

  React.useEffect(() => {
    if (account && isCorrectNetwork && !isConnectionFailed) {
      (async () => {
        setLoadingSkey(true);
        setSkeyBalance(await getSkeyBalance(account));
        // await updateNativeTokenPrice();
      })();
    } else {
      setSkeyBalance(0n);
      setNativeTokenPrice(0n);
    }
  }, [
    account,
    status,
    isCorrectNetwork,
    isConnectionFailed,
    getSkeyBalance,
    setNativeTokenPrice
  ]);

  React.useEffect(() => {
    if (account && transactionStatus === TransactionStatus.DONE) {
      (async () => {
        setSkeyBalance(await getSkeyBalance(account));
        // await updateNativeTokenPrice();
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionStatus, account]);

  return { skeyBalance, loadingSkey };
};

export default useSkeyBalance;
