import React from "react";
import usePresale from "./usePresale";
import useProvider from "./useProvider";
import useToken from "./useToken";
import { TransactionStatus, useModalInfo } from "../context/ModalInfoContext";
import { BigNumber } from "ethers";

interface UseG2nBalance {
  account: string | null;
  status: string;
  isCorrectNetwork: boolean;
  isConnectionFailed: boolean;
}

const useG2nBalance = ({
  account,
  status,
  isCorrectNetwork,
  isConnectionFailed
}: UseG2nBalance) => {
  const [g2nBalance, setG2nBalance] = React.useState<bigint>(0n);
  const [loadingG2n, setLoadingG2n] = React.useState(false);

  const { transactionStatus } = useModalInfo();
  const { bscProvider, ethProvider } = useProvider();
  const { g2nToken } = useToken({ bscProvider, ethProvider });
  const { setNativeTokenPrice, allBurnPresales, allNativePresales } =
    usePresale();

  const getG2nBalance = React.useCallback(
    async (account: string) => {
      if (allBurnPresales.length === 0 || allNativePresales.length === 0) {
        setLoadingG2n(false);
        return g2nBalance;
      }

      try {
        const totalBurnBalanceBigInt = (
          await Promise.all(
            allBurnPresales.map((presale) => {
              return (
                presale
                  .userData(account)

                  // TODO
                  // Remove when burn presale is added
                  .catch(
                    () =>
                      ({
                        balance: BigNumber.from(0),
                        maxBalance: BigNumber.from(0)
                      } as any)
                  )
              );
            })
          )
        )
          .map((resolved) => {
            return resolved.balance.toBigInt();
          })
          .reduce((prev, next) => prev + next);

        const totalNativeBalanceBigInt = (
          await Promise.all(
            allNativePresales.map((presale) => {
              return presale.userData(account);
            })
          )
        )
          .map((resolved) => {
            return resolved.balance.toBigInt();
          })
          .reduce((prev, next) => prev + next);

        setLoadingG2n(false);
        const totalG2nBalanceBigInt =
          // totalBurnBalanceBigInt +
          totalNativeBalanceBigInt;

        return totalG2nBalanceBigInt;
      } catch (err) {
        setLoadingG2n(false);
        console.error("Couldn't fetch G2N balance", err);
        return 0n;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [g2nToken, account]
  );

  React.useEffect(() => {
    if (account && isCorrectNetwork && !isConnectionFailed) {
      (async () => {
        setLoadingG2n(true);
        setG2nBalance(await getG2nBalance(account));
      })();
    } else {
      setG2nBalance(0n);
      setNativeTokenPrice(0n);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    account,
    status,
    isCorrectNetwork,
    // updateNativeTokenPrice,
    isConnectionFailed
  ]);

  React.useEffect(() => {
    if (account && transactionStatus === TransactionStatus.DONE) {
      (async () => {
        setLoadingG2n(true);
        setG2nBalance(await getG2nBalance(account));
        // await updateNativeTokenPrice();
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionStatus, account]);

  return { g2nBalance, loadingG2n };
};

export default useG2nBalance;
