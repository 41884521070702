import { Config, Networks, NetworkStages } from "../networkInfo";

export const shortAddress = (addr: string, n: number): string => {
  return `${addr.substring(0, n + 2)}...${addr.substring(
    addr.length - n,
    addr.length
  )}`;
};

// export const getStringFromBigInt = (value: bigint, token: string): string => {
//   return (
//     Number((value * 10n ** 18n) / getBigIntPrecision(token)) /
//     10 ** 18
//   ).toFixed(8);
// };

// export const getBigIntPrecision = (token: string): bigint => {
//   switch (token) {
//     case "BNB":
//       return 10n ** 18n;
//     case "G2N":
//       return 10n ** 4n;
//     case "USDT":
//       return 10n ** 6n;
//     case "$":
//       return 10n ** 8n;
//     case "Skey":
//       return 10n ** 8n;
//     default:
//       return 0n;
//   }
// };

// export const getPrecision = (token: string): number => {
//   switch (token) {
//     case "BNB":
//       return 10 ** 18;
//     case "G2N":
//       return 10 ** 4;
//     case "USDT":
//       return 10 ** 6;
//     case "$":
//       return 10 ** 8;
//     case "Skey":
//       return 10 ** 8;
//     default:
//       return 0;
//   }
// };

// export const getBigIntFromString = (value: string): bigint => {
//   return BigInt(value);
// };

// export const bnbBigIntPrecision = (wantedBnb: number): number => {
//   return Math.round(Math.floor(wantedBnb * 10 ** 18));
// };

// export const g2nBigIntPrecision = (wantedG2n: number): number => {
//   return Math.floor(wantedG2n * 10 ** 4) ?? 0;
// };

// export const skeyBigIntPrecision = (wantedSkey: number): bigint => {
//   return BigInt(Math.round(Math.floor(wantedSkey * 10 ** 8))) ?? 0n;
// };

// export const dollarBigIntPrecision = (wantedDollars: number): bigint => {
//   return BigInt(Math.round(Math.floor(wantedDollars * 10 ** 8))) ?? 0n;
// };

// export const howMuchDollarsForBnb = (
//   value: string,
//   nativeTokenPrice: bigint
// ): number => {
//   if (!nativeTokenPrice || !value) return 0;

//   const bigIntValue = BigInt(Math.round(parseFloat(value) * 10 ** 18));
//   return Number((bigIntValue * nativeTokenPrice) / 10n ** 8n);
// };

// export const howMuchDollarsForSkey = (
//   skeyToSpend: string,
//   skeyUnitPrice: bigint | null
// ): number => {
//   if (!skeyToSpend || !skeyUnitPrice) return 0;
//   return (
//     Number(
//       BigInt(Math.floor(parseFloat(skeyToSpend) * 10 ** 8)) * skeyUnitPrice
//     ) /
//     10 ** 16
//   );
// };

// export const howMuchDollarsForG2n = (
//   g2nUnitPrice: bigint,
//   g2nAmount: bigint
// ): bigint => {
//   console.log(g2nUnitPrice);
//   console.log(g2nAmount);
//   console.log((g2nUnitPrice * g2nAmount) / 10n ** 8n);
//   const totalPrice = (g2nUnitPrice * g2nAmount) / 10n ** 8n;
//   return totalPrice;
// };

export const buildMetamaskLink = (url: string) => {
  return `https://metamask.app.link/dapp/${window.location.host.toString()}${url}`;
};

export const pickNetworkStageFromConfig = (
  networkStage: NetworkStages,
  config: Config
) => {
  if (networkStage === NetworkStages.TESTNET) {
    return { ...config.networks.testnet };
  }
  if (networkStage === NetworkStages.MAINNET) {
    return { ...config.networks.mainnet };
  }

  throw new Error("Wrong network stage picked in networkInfo.ts");
};

export const pickDefaultNetwork = (correctNetworkStage: any) => {
  return { ...correctNetworkStage.binance };
};

export const pickNetworkInfo = (config: any, network: Networks) => {
  if (network === Networks.BSC) return { ...config.binance };
  if (network === Networks.ETHEREUM) return { ...config.ethereum };

  throw new Error(
    `There is no network like: ${network}. Expected values: ${Networks.BSC} or ${Networks.ETHEREUM}`
  );
};

// export const bigintToNumberDollars = (
//   bigIntDollars: bigint,
//   precision: bigint
// ) => {
//   if (!bigIntDollars) return "0";

//   return (Number(bigIntDollars) / Number(precision)).toFixed(2);
// };

// export const convertBnbPrecisionToDollar = (bigintDollars: number) => {
//   return (bigintDollars / 10 ** 18).toFixed(2);
// };

// export const convertSkeyPrecisionToDollar = (bigintDollars: number) => {
//   return (bigintDollars / 10 ** 16).toFixed(2);
// };

// export const bnbBigIntToString = (bnb: number): string => {
//   return (bnb / 10 ** 18).toFixed(18);
// };

export const isInputLowerThan = (input: string, than: number) => {
  return (
    BigInt(Number(Math.round(parseFloat(input) * 10 ** 18))) < BigInt(than)
  );
};
