export type Token = "BNB" | "G2N" | "$" | "Skey";

const fixed = (token: Token): number => {
  switch (token) {
    case "BNB":
      return 18;
    case "G2N":
      return 4;
    case "$":
      return 8;
    case "Skey":
      return 8;
    default:
      throw new Error("There is no such token");
  }
};

export class BigToken {
  token: Token;
  precision: number;
  tokensAmount: string | number | bigint | undefined;
  unitPrice: number | bigint | undefined | null;
  total: bigint;
  fixed: number;

  constructor(
    tokenType: Token,
    tokensAmount: string | number | bigint | undefined,
    unitPrice: number | bigint | undefined | null
  ) {
    this.token = tokenType;
    this.precision = 10 ** fixed(this.token);
    this.tokensAmount = tokensAmount;
    this.unitPrice = unitPrice;
    this.total = this.totalTokensPriceInBigInt();
    this.fixed = fixed(this.token);
  }
  //Transforms any value into BigInt
  toBigInt() {
    if (!this.tokensAmount) return BigInt(0n);
    if (typeof this.tokensAmount === "bigint") {
      return this.tokensAmount;
    }
    if (typeof this.tokensAmount === "string") {
      return BigInt(Math.round(parseFloat(this.tokensAmount) * this.precision));
    }
    if (typeof this.tokensAmount === "number") {
      return BigInt(Math.round(this.tokensAmount * this.precision));
    }
    throw new Error("Wrong type");
  }

  //Returns given tokens price in dollars in BigInt
  totalTokensPriceInBigInt() {
    if (!this.unitPrice) return 0n;
    if (typeof this.unitPrice === "number") {
      const dollarBigIntPrecission = BigInt(
        Math.round(this.unitPrice * 10 ** fixed("$"))
      );
      return (
        (dollarBigIntPrecission * this.toBigInt()) / BigInt(this.precision)
      );
    }
    if (typeof this.unitPrice === "bigint") {
      return (this.unitPrice * this.toBigInt()) / BigInt(this.precision);
    }
    throw new Error("Wrong unitPrice type");
  }

  //Reduces BigInt to fixed string depending on belonging token precision
  getReducedTokensAmount() {
    return (Number(this.tokensAmount) / 10 ** fixed(this.token)).toFixed(
      fixed(this.token)
    );
  }

  //Returns BigToken instance with only one token
  //and given unit price. Needed to define how much one token we need
  //to get amount of other token, depending on price
  getSingleToken() {
    return new BigToken(this.token, 1, this.unitPrice);
  }

  //Number representation of given token price in dollars
  totalTokensPriceInNumber() {
    return Number(this.totalTokensPriceInBigInt()) / 10 ** fixed("$");
  }

  //String representation of given token price in dollars with toFixed(2)
  totalTokensPriceInStringShort() {
    return this.totalTokensPriceInNumber().toFixed(2);
  }

  //String representation of given token price in dollars with toFixed(8)
  totalTokensPriceInStringLong() {
    return (
      Number(this.totalTokensPriceInBigInt()) /
      10 ** fixed(this.token)
    ).toFixed(fixed("$"));
  }
}
