import React from "react";
import { NativePresale__factory } from "../Factories/factories/NativePresale__factory";
import { BurnPresale__factory } from "../Factories/factories/BurnPresale__factory";
import useProvider from "./useProvider";
import { useReflinkForContract } from "./useReflinkForContract";
import { useMetaMask } from "metamask-react";

const usePresale = () => {
  const { account } = useMetaMask();
  const [nativeTokenPrice, setNativeTokenPrice] = React.useState<bigint>();
  const {
    nativeContractInfo,
    burnContractInfo,
    allBurnContracts,
    allNativeContracts
  } = useReflinkForContract();
  const { bscProvider } = useProvider();

  const nativePresale = React.useMemo(
    () => {
      return NativePresale__factory.connect(
        nativeContractInfo.contract,
        bscProvider
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bscProvider, nativeContractInfo, account]
  );

  const burnPresale = React.useMemo(
    () => {
      return BurnPresale__factory.connect(
        burnContractInfo.contract,
        bscProvider
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bscProvider, burnContractInfo, account]
  );

  const allNativePresales = () => {
    return allNativeContracts.map((contractInfo) => {
      return NativePresale__factory.connect(contractInfo.contract, bscProvider);
    });
  };

  const allBurnPresales = () => {
    return allBurnContracts.map((contractInfo) => {
      return BurnPresale__factory.connect(contractInfo.contract, bscProvider);
    });
  };

  const updateNativeTokenPrice = React.useCallback(async () => {
    const currentNativeTokenPrice = (
      await nativePresale.getNativeTokenPrice()
    ).toBigInt();
    setNativeTokenPrice(currentNativeTokenPrice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nativePresale, nativeContractInfo]);

  return {
    nativePresale,
    burnPresale,
    nativeTokenPrice,
    updateNativeTokenPrice,
    setNativeTokenPrice,
    allBurnPresales: allBurnPresales(),
    allNativePresales: allNativePresales()
  };
};

export default usePresale;
