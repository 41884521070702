import React from "react";
import { useBuyToken } from "../../../context/BuyTokenContext";
import { WayOfBuyingG2n } from "../../../context/ConfigContext";
import useUIPresets from "../../../hook/useUIPresets";
import FullWidthBox from "../../components/FullWidthBox";
import ConnectButton from "../BuyTokenContent/ConnectButton";
import Address from "./Address";
import BnbBalance from "./BnbBalance";
import SkeyBalance from "./SkeyBalance";

const BuyTokenNavbar = () => {
  const {
    status,
    account,
    isCorrectNetwork,
    bnbBalance,
    loadingBnb,
    paymentMethod,
    nativeTokenPrice,
    loadingSkey,
    skeyTokenPrice,
    skeyBalance
  } = useBuyToken();
  const { colors } = useUIPresets();

  const isMetamaskConnected = () => {
    return status === "connected";
  };

  return (
    <nav id="buy-token-nav">
      <FullWidthBox
        bgColor={colors.bgGray}
        style={{ height: "96px" }}
        className="flex-center-between padding"
        paddingLeftRight="50px"
      >
        <a href={"/"} style={{ width: "max-content", height: "max-content" }}>
          <img src={"images/svg/brand-mobile.svg"} alt="g2n logo" />
        </a>
        <div className="metamask-info__holder">
          <button disabled={true} className="claim-tokens-button">
            Claim Tokens
          </button>
          {isMetamaskConnected() ? (
            <>
              {paymentMethod === WayOfBuyingG2n.BNB && (
                <BnbBalance
                  balance={bnbBalance}
                  nativeTokenPrice={nativeTokenPrice}
                  isCorrectNetwork={isCorrectNetwork}
                  loading={loadingBnb}
                />
              )}
              {paymentMethod === WayOfBuyingG2n.BURN_SKEY && (
                <SkeyBalance
                  balance={skeyBalance}
                  skeyUnitPrice={skeyTokenPrice}
                  isCorrectNetwork={isCorrectNetwork}
                  loading={loadingSkey}
                />
              )}
              <Address account={account as string} />
            </>
          ) : (
            <ConnectButton />
          )}
        </div>
      </FullWidthBox>
    </nav>
  );
};

export default BuyTokenNavbar;
