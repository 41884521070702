import React from "react";
import Input from "./Input";

interface SkeyInputProps {
  balanceForSkey: string;
  skeyInput: {
    value: string;
    touched: boolean;
    error: string;
  };
  isCorrectNetwork: boolean;
  isInputDisabled: Function;
  writeToSkeyInput: Function;
  loadingSkey: boolean;
  setSkeyInput: Function;
}

const SkeyInput = ({
  balanceForSkey,
  isCorrectNetwork,
  isInputDisabled,
  loadingSkey,
  setSkeyInput,
  skeyInput,
  writeToSkeyInput
}: SkeyInputProps) => {
  return (
    <Input
      className={"skey-input crypto-input"}
      inputName={"Skey"}
      balance={balanceForSkey}
      value={skeyInput.value}
      touched={skeyInput.touched}
      error={skeyInput.error}
      isCorrectNetwork={isCorrectNetwork}
      placeholder={"Skey to spend"}
      isDisabled={isInputDisabled()}
      setNewValue={writeToSkeyInput}
      loading={loadingSkey}
      click={() => setSkeyInput({ ...skeyInput, touched: true })}
    />
  );
};

export default SkeyInput;
