import React from "react";
import { Spinner } from "react-bootstrap";

interface G2nConvertRatioSkeyProps {
  howMuchG2nToGet: string;
}

const G2nConvertRatioSkey = ({ howMuchG2nToGet }: G2nConvertRatioSkeyProps) => {
  if (isNaN(Number(howMuchG2nToGet)) || !isFinite(Number(howMuchG2nToGet))) {
    return (
      <span>
        1 Skey = <Spinner animation="border" size="sm" /> G2N
      </span>
    );
  }

  if (
    isFinite(Number(howMuchG2nToGet)) &&
    !isNaN(Number(howMuchG2nToGet)) &&
    !!howMuchG2nToGet
  ) {
    return <p>1 Skey = {howMuchG2nToGet} G2N</p>;
  }

  return <p>1 Skey = G2N</p>;
};

export default G2nConvertRatioSkey;
